import React from "react";
import {useTranslation} from "react-i18next";

const IP_DJANGO = process.env.REACT_APP_DJANGO_IP;
const PORT_DJANGO = process.env.REACT_APP_DJANGO_PORT;
const HTTP_PROTOCOL = process.env.REACT_APP_HTTP_PROTOCOL;

export default function BookingCard(props) {
    const {t, i18n} = useTranslation('common');

    const date = (prop) => {
        let date = prop?.replace("-", "/");
        return new Date(date).toLocaleString().split(" ")[0]
    };

    const css_reservation_status = (status = props.reservation.status_room) => {
        if (status === 'confirmed' || status === 'completed') {
            return 'label confirmed'
        } else if (status === 'canceled') {
            return 'label cancelled'
        } else if (status === 'pending') {
            return 'label'
        } else {
            return 'label'
        }
    };

    const numChilds = () => {
        return props.reservation.children2 + props.reservation.children + props.reservation.babies
    };

    const roomImage = HTTP_PROTOCOL + "://" + IP_DJANGO + ":" + PORT_DJANGO + props.reservation.roomimage;
    const url_reservation = "/myreservations/room/" + props.reservation.pms_resid_key;

    return (
        <article className="booking-card">
            <p className={css_reservation_status()}>
                <span>
                {t([`reservation_status.${props.reservation.status_room}`, 'reservation_status.pending'])}
                </span>
            </p>
            <div className="booking-img" style={{backgroundImage: `url("${roomImage}")`}}>
                <img src={HTTP_PROTOCOL + "://" + IP_DJANGO + ":" + PORT_DJANGO + props.reservation.roomimage}/>
            </div>
            <div className="booking-info">
                <div className="principal-info">
                    <p className="title">{t([`roomtypes.${props.reservation.translation_code}`, 'roomtypes.unspecific']) + " - Hotel " + props.reservation?.hotel}</p>
                    <a href={url_reservation}
                       className="h2 locator">{t('reservation.reservation_id_short')}: {props.reservation.pms_resid_key}</a>
                    <table className="date">
                        <tr>
                            <th>{t('words.arrival_date')}:</th>
                            <th>{t('words.departure_date')}:</th>
                        </tr>
                        <tr>
                            <td>{props.reservation.arrival}</td>
                            <td>{props.reservation.departure}</td>
                        </tr>
                    </table>
                    <p className="info">
                        <strong>{props.reservation.adults} {t('search.adults')}{numChilds() > 0 ? ', ' + numChilds() + ' ' + t('search.children') : null}</strong>
                    </p>
                    <br/>
                    <p className="info">
                        {i18n.language === "es" ?
                            <strong><a href="https://springhoteles.com/formulario-taxi-gratuito/"
                                       className="right-link">Solicitar Taxi Transfer</a></strong>
                            :
                            <strong><a href="https://springhoteles.com/en/taxi-transfer-form/" className="right-link">Taxi
                                Transfer</a></strong>
                        }
                    </p>

                </div>
                <div className="footer-info">
                    {(props.reservation.client_price === props.reservation.booking_price) || (props?.isAgency) ?
                        <p className="price"><strong>{props.reservation.client_price}€</strong></p>
                        :
                        <p className="price"><strike>{props.reservation.booking_price}€</strike>
                            <strong> {props.reservation.client_price}€</strong></p>
                    }
                    {(props.reservation.status_room !== "canceled") &&
                        <p className="links-wrapper">
                            <a href={url_reservation} className="right-link">{t('words.modify')}</a>
                        </p>
                    }
                </div>
            </div>
        </article>
    )
}