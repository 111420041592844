import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import {Paper, Grid, Box, Button} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";


const styles = theme => ({
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  baseline: {
    alignSelf: 'baseline',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: 0
    }
  },
  inline: {
    display: 'inline-block',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  inlineRight: {
    width: '30%',
    textAlign: 'right',
    marginLeft: 50,
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      textAlign: 'center'
    }
  },
  backButton: {
    marginRight: theme.spacing(2)
  }
});


class BoardTypeItem extends Component {

  render() {
      const { classes } = this.props;
      let button;
      let buttonclub;
      if (this.props.Availability > 0) {
          button = <Button variant="contained" color="primary"> { this.props.pricewithoffer } </Button>;
          buttonclub = <Button variant="contained" color="primary">
              { parseFloat(this.props.pricewithoffer) - parseFloat(this.props.pricewithoffer)*0.05 }
          </Button>;
      } else {
          button = buttonclub = <Button variant="contained" color="primary" disabled={true}> </Button>;
      }

      let ahorro;
      try {
          let priceDifference = (Number.parseFloat((this.props.pricewithoutoffer).replace(",", ".")) -
              Number.parseFloat((this.props.pricewithoffer).replace(",", "."))).toFixed(2);
          if (priceDifference > 0) {
              ahorro = "(Ahorra " + priceDifference + ")"
          }
          else {
              ahorro = ""
          }
      } catch(e) {}

      return (
        <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Box display="flex" bgcolor="background.paper">
                        <Grid item xs>
                            <Box align="left">
                                <Typography>
                                { this.props.boardtype }
                                </Typography>
                            </Box>
                            <Box align="left" fontWeight="fontWeightBold">
                                <Typography style={{ textTransform: 'uppercase' }} color='secondary' gutterBottom>
                                (<strike> { this.props.pricewithoutoffer } </strike>) { this.props.pricewithoffer }
                                </Typography>
                             </Box>
                        </Grid>
                        <Grid item xs>
                            <Box align="center">
                                <Typography> Oferta </Typography>
                            </Box>
                            <Box align="center">
                                <Typography color='secondary'> {ahorro} </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs>
                            <Box align="center">
                                <Typography color='secondary'> PRECIO WEB </Typography>
                            </Box>
                            <Box align="center">
                                <Typography> (Antes <strike> { this.props.pricewithoutoffer } </strike>) </Typography>
                            </Box>
                            <Box align="center">
                                {button}
                            </Box>
                        </Grid>
                        <Grid item xs>
                            <Box align="center">
                                <Typography color='secondary'> SPRING CLUB </Typography>
                            </Box>
                            <Box align="center">
                                <Typography> 5% de Descuento Extra Gratis</Typography>
                            </Box>
                            <Box align="center">
                                {buttonclub}
                            </Box>
                        </Grid>
                    </Box>
                </Paper>
        </div>
      );
  }
}

export default withStyles(styles)(BoardTypeItem);