import React, {useState} from 'react';
import Cards from 'react-credit-cards';
import {useFormContext} from "react-hook-form";

import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
} from "./utilsPayment";

import 'react-credit-cards/es/styles-compiled.css';
import {useTranslation} from "react-i18next";
import {checkoutGTM} from "../../GTMComponent/GTMComponent";


export default function CreditCardPayment(params) {
    const [creditCard, setCreditCard] = useState({
        cvc: "",
        expiry: "",
        focus: "",
        cardname: "",
        number: "",
    });

    const {register} = useFormContext();
    const {t} = useTranslation('common');

    const handleInputFocus = (e) => {
        setCreditCard({...creditCard, focus: e.target.name});
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;

        if (e.target.name === "number") {
            e.target.value = formatCreditCardNumber(e.target.value);
        } else if (e.target.name === "expiry") {
            e.target.value = formatExpirationDate(e.target.value);
        } else if (e.target.name === "cvc") {
            e.target.value = formatCVC(e.target.value);
        }

        setCreditCard({
            ...creditCard,
            [name]: value,
        });
    };

    if(creditCard.cvc.toString().length > 2){
        new checkoutGTM(4);
    }

    return (
        <>
            <dl className="checkout-box payment-data">
                <dt>
                    <h2>{t('credicard.titulo_bloque')}</h2>
                </dt>
                <dd>
                    <div className="header">
                        <h3>{t('credicard.titulo_contenido')}</h3>
                        {/* TODO traduccion y menor espaciado */}
                        <p>{t('credicard.parrafo_contenido')}.</p>
                        <p>Cancelación gratuita hasta 24h antes de la llegada. Si no cancela y no se presenta en el hotel se le cobrará una noche (más impuestos).</p>
                    </div>
                    <div className="form-wrapper">

                        <Cards
                            cvc={creditCard.cvc}
                            expiry={creditCard.expiry}
                            focused={creditCard.focus}
                            name={creditCard.cardname}
                            number={creditCard.number}
                        />

                        <br/>

                        <div className="text-block">
                            <div className="input-wrapper">
                                <label htmlFor="card_number">{t('credicard.numero_tarjeta')} <span>({t('words.required')})</span></label>
                                <input
                                    type="text"
                                    id="card_number"
                                    name="number"
                                    placeholder="0000 - 0000 - 0000 - 0000"
                                    pattern="[\d| ]{15,22}"
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                    ref={register}
                                    required
                                />
                                <p className="error-msg">Introduce un número de tarjeta válido</p>
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="card_name">{t('credicard.titular_tarjeta')} <span>({t('words.required')})</span></label>
                                <input
                                    type="text"
                                    id="card_name"
                                    name="cardname"
                                    placeholder={t('words.full_name')}
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                    ref={register}
                                    required
                                />
                            </div>
                            <div className="column-inputs also-mobile">
                                <div className="input-wrapper">
                                    <label htmlFor="card_month">{t('credicard.fecha_caducidad')} <span>({t('words.required')})</span></label>
                                    <input
                                        type="text"
                                        placeholder="00/00"
                                        id="card_month"
                                        name="expiry"
                                        pattern="\d\d/\d\d"
                                        onChange={handleInputChange}
                                        onFocus={handleInputFocus}
                                        ref={register}
                                        required
                                    />
                                    <p className="error-msg">Fecha no válida.</p>
                                </div>
                                <div className="input-wrapper">
                                    <label htmlFor="card_cvv">{t('credicard.cvc')} <span>({t('words.required')})</span> <a
                                        href="#modal_cvv_info" className="ico-info motor-modal-link"
                                        title="info"></a></label>
                                    <input
                                        type="text"
                                        id="card_cvv"
                                        name="cvc"
                                        placeholder="000"
                                        pattern="\d{3,4}"
                                        onChange={handleInputChange}
                                        onFocus={handleInputFocus}
                                        ref={register}
                                        required
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </dd>
            </dl>
        </>
    );
};