import React from "react";
import ImageGallery from 'react-image-gallery';

import "react-image-gallery/styles/css/image-gallery.css";

class RoomGallery extends React.Component {

  render() {

    let imageArray =  this.props.sliderimages;
    let images = [];

    let getImages = (array) => {
      if(array && array.length) {
        for (let i = 0; i < array.length; i++) {
          images.push({original: array[i],})
        }
      } else {
        images.push(
            {original: 'https://www.springhoteles.com/content/files/1/featuredsImages/home-sel-bitacora.jpg',},
            {original: 'https://www.springhoteles.com/content/files/1/featuredsImages/home-sel-vulcano.jpg',},
            )
      }
    };

    getImages(imageArray);

    return (
      <ImageGallery
          items={images}
          showThumbnails={false}
          showPlayButton={false}
          showNav={false}
          showBullets={true}
      />
    );
  }

}

export default RoomGallery;