import React, {Component} from 'react';
import PropTypes, {instanceOf} from "prop-types";
import {connect} from "react-redux";
import LoginPage from "../Login/LoginPage";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Bookings from "./Bookings/Bookings";
import {logout} from "../../actions/auth";
import {spring40Update} from "../../actions/agency";
import {MainMessage} from "./CallCenterUtils"
import {Cookies, withCookies} from "react-cookie";
import IcoInfo from "../../static/img/icons/ico-notice.svg";
import AgentsPage from "./Agency/AgentsPage";
import Popup from "reactjs-popup";
import {SearchBox} from "../SearchBox/SearchBox";
import Toggle from 'react-toggle'


export class Dashboard extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired,
        cookies: instanceOf(Cookies).isRequired
    };

    modeSpring40() {
        return <>
            <div className="system-msg success-msg">
                <div className="msg-box">
                    <div id="hr" className="hotel-banner">
                        <div className="text-content">
                            <p>SPRING 40&nbsp;&nbsp;</p>
                        </div>
                        <Toggle
                            id='spring40-status'
                            defaultChecked={this.props.spring40}
                            onChange={() =>
                                this.props.spring40Update(!this.props.spring40)
                            }/>
                    </div>
                </div>
            </div>
            {this.props.spring40 && <SearchBox/>}
        </>
    }

    extraTabs(t, page) {
        if (this.props?.isSpring40) {
                return <>
                    <li>
                        <Popup modal trigger={
                            <a>SPRING 40</a>
                        } position="right center">
                            {this.modeSpring40()}
                        </Popup>
                    </li>
                </>
        } else if (this.props.auth?.user?.rol === 'agent') {
            return <li>
                <Popup modal trigger={
                    <a>SPRING 40</a>
                } position="right center">
                    {this.modeSpring40()}
                </Popup>
            </li>
        }
    };

    render() {
        const {isAuthenticated} = this.props.auth;
        const page = this.props.page;
        const {t} = this.props;

        return (
            <>
                {isAuthenticated ?
                    <>
                        <Header hotel="spring"/>

                        {this.props.callcenter.callCenterActive && <MainMessage/>}
                        {this.props.isAgency && <div className="system-msg notice-msg">
                            <div className="msg-box">
                                <img className="icon icon-notice" src={IcoInfo}/>
                                <div className="text-content">
                                    <h5 className="title">{t('agencies.welcome_msg')}.</h5>
                                    <p>{this.props.agency?.name}</p>
                                </div>
                            </div>
                        </div>
                        }
                        {(this.props.auth.user?.rol === "agent") && <div className="system-msg notice-msg">
                            <div className="msg-box">
                                <img className="icon icon-notice" src={IcoInfo}/>
                                <div className="text-content">
                                    <h5 className="title">{this.props.auth.user?.name}</h5>
                                    <p>{this.props.auth.user?.agency_name}</p>
                                </div>
                            </div>
                        </div>
                        }

                        <main className="container apriv-page">
                            <header className="apriv-header">
                                <a className="logout-link" onClick={() => this.props.logout(this.props.cookies)}>
                                    <span>{t('words.logout')}</span>
                                    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
                                        <g id="Área-Privada" stroke="none" stroke-width="1" fill="none"
                                           fill-rule="evenodd">
                                            <g id="APriv---Reservas---3-reservas---Desktop"
                                               transform="translate(-1297.000000, -131.000000)" fill="currentColor"
                                               fill-rule="nonzero">
                                                <g id="Group-6" transform="translate(1197.000000, 123.000000)">
                                                    <g id="Group-4" transform="translate(91.000000, 0.000000)">
                                                        <g id="logout" transform="translate(9.000000, 8.000000)">
                                                            <path
                                                                d="M11.4234182,4.09173333 C11.1394909,3.80775758 10.6789333,3.80770909 10.3949091,4.09168485 C10.1109333,4.37570909 10.1108848,4.8361697 10.3949091,5.12019394 L12.5473455,7.27272727 L3.63646061,7.27272727 C3.23481212,7.27272727 2.90918788,7.59835152 2.90918788,8 C2.90918788,8.40164848 3.23481212,8.72727273 3.63646061,8.72727273 L12.5472485,8.72727273 L10.3949091,10.8796121 C10.1108848,11.1636364 10.1108848,11.624097 10.3949091,11.9081212 C10.5369212,12.0501333 10.7230545,12.1211152 10.9091879,12.1211152 C11.0953212,12.1211152 11.2814545,12.0501333 11.4234182,11.9081212 L14.8172606,8.51427879 C15.1012848,8.23030303 15.1012848,7.76979394 14.8172606,7.4857697 L11.4234182,4.09173333 Z"
                                                                id="Path"></path>
                                                            <path
                                                                d="M7.03030303,16 C7.43195152,16 7.75757576,15.6743758 7.75757576,15.2727273 C7.75757576,14.8710788 7.43195152,14.5454545 7.03030303,14.5454545 L1.45454545,14.5454545 L1.45454545,1.45454545 L7.03030303,1.45454545 C7.43195152,1.45454545 7.75757576,1.12892121 7.75757576,0.727272727 C7.75757576,0.325624242 7.43195152,0 7.03030303,0 L0.727272727,0 C0.325624242,0 0,0.325624242 0,0.727272727 L0,15.2727273 C0,15.6743758 0.325624242,16 0.727272727,16 L7.03030303,16 Z"
                                                                id="Path"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </a>

                                <nav className="apriv-menu">
                                    <ul>
                                        <li><Link to="/myreservations"
                                                  className={page === "bookings" && "current"}>{t('words.reservations')}</Link>
                                        </li>
                                        {this.extraTabs(t, page)}
                                        <li><Link to="/">{t('msg.new_booking')}</Link></li>
                                    </ul>
                                </nav>
                            </header>

                            {/*{page === 'userdata' && <UserData/>}*/}
                            {page === 'bookings' && <Bookings/>}
                            {page === 'agents' && <AgentsPage/>}

                        </main>

                        <Footer hotel="spring"/>
                    </>
                    :
                    <LoginPage/>
                }
            </>

        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    isAgency: state.auth.isAgency,
    agency: state.auth.agency,
    agents: state.agency.agents,
    callcenter: state.callcenter,
    spring40: state.agency.spring40active,
    isSpring40: state.auth.agency?.is_spring40
});

export default connect(
    mapStateToProps, {logout, spring40Update}
)(withCookies(withTranslation('common')(Dashboard)));