import React, {useCallback, useState} from "react";
import {useFormContext} from "react-hook-form";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {confirmBookingButtonUpdate} from "../../../../actions/checkout";

import {checkoutGTM} from "../../../GTMComponent/GTMComponent";

import CircularProgress from "@material-ui/core/CircularProgress";
import RegisterForm from "./register-form";
import ClubBanner from "../clubBanner";
import CreditCardPayment from "../paymentForm";
import LoginForm from "./login-form";


const IP_DJANGO = process.env.REACT_APP_DJANGO_IP;
const PORT_DJANGO = process.env.REACT_APP_DJANGO_PORT;
const HTTP_PROTOCOL = process.env.REACT_APP_HTTP_PROTOCOL;
const baseUrl = HTTP_PROTOCOL + '://' + IP_DJANGO + ':' + PORT_DJANGO;


export default function PersonalForm() {

    const {t} = useTranslation('common');

    const dispatch = useDispatch();

    const {register, errors} = useFormContext(); // retrieve all hook methods

    const [userEmail, setUserEmail] = useState(); // email del usuario

    const [loginForm, setLoginForm] = useState(false);
    const [registerForm, setRegisterForm] = useState(false);
    const [passwordForm, setPasswordForm] = useState(false);

    const [loading, setLoading] = useState(false);

    const configRequest = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    const loadEmail = useCallback(async (email) => {
        const response = await axios.post(baseUrl + '/api/auth/user/email', JSON.stringify({"email": email}), configRequest)
            .then(res => {
                setLoading(false);
                setUserEmail(email);
                dispatch(confirmBookingButtonUpdate(false));
                // 0 en nada, 1 En NAV, 2 En NAV y Motor
                if (res.data.email_result === 2) {
                    setLoginForm(true);
                    setRegisterForm(false);
                    setPasswordForm(false);
                } else if (res.data.email_result === 1) {
                    setPasswordForm(true);
                    setRegisterForm(false);
                    setLoginForm(false);
                } else {
                    setRegisterForm(true);
                    setLoginForm(false);
                    setPasswordForm(false);
                }
                new checkoutGTM(1);
            })
            .catch(err => {
                console.log("API Checkout Error", err);
            });
    }, []);


    return (
        <React.Fragment>
            <dl className="checkout-box">
                <dd>
                    <div className="column-inputs">
                        <div className="input-wrapper">
                            <label htmlFor="email">{t('words.email')} <span>({t('words.required')})</span></label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="username@email.com"
                                onChange={e => {
                                    const email = e.target.value;
                                    // Regex email
                                    if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
                                        setLoading(true);
                                        loadEmail(email);
                                    }
                                }}
                                ref={register({
                                    required: "Required",
                                    pattern: {
                                        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                                        message: "Please enter a valid email."
                                    }
                                })}
                            />
                            {errors.email && <p className="error-msg-red">{errors.email.message}</p>}

                        </div>

                        {loading &&
                        <div className="input-wrapper max-padding">
                            <CircularProgress style={{color: 'black'}}/>
                        </div>
                        }


                    </div>
                </dd>

            </dl>

            {loginForm === true ? <LoginForm/> : null}

            {/*{(passwordForm || loginForm) === true ?*/}
            {passwordForm === true ?
                <React.Fragment>
                    <dd>
                        <div className="header">
                            <h3>{t('usuario_pms_primera_reserva.titulo_bloque_usuario_pms_primera_reserva')}</h3>
                            <br/><br/>
                            <p>{t('usuario_pms_primera_reserva.parrafo_contenido_0_usuario_pms_primera_reserva')}.</p>
                            <p>{t('usuario_pms_primera_reserva.parrafo_contenido_1_usuario_pms_primera_reserva')}.</p>
                            <p>{t('usuario_pms_primera_reserva.parrafo_contenido_2_usuario_pms_primera_reserva')}.</p>
                            <p>
                                <strong>{t('usuario_pms_primera_reserva.parrafo_contenido_3_usuario_pms_primera_reserva')}.</strong>
                            </p>
                        </div>
                    </dd>
                    <RegisterForm newuser={false}/>
                    <ClubBanner required={true}/>
                    <CreditCardPayment/>
                </React.Fragment>
                : null}

            {registerForm === true ?
                <React.Fragment>
                    <RegisterForm newuser={true}/>
                    <ClubBanner required={true}/>
                    <CreditCardPayment/>
                </React.Fragment>
                : null}

        </React.Fragment>
    )
};