import {
    SEARCH,
} from './types';


export const createSearch = searchdata => {
  return {
    type: SEARCH,
    payload: searchdata,
  };
};
