import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from './reducers';
import createReduxPromiseListener from 'redux-promise-listener'
import asyncSubmissionMiddleware from './components/asyncSubmissionMiddleware'


const initialState = JSON.parse(window.localStorage.getItem('state')) || {};

const reduxPromiseListener = createReduxPromiseListener();

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(
      reduxPromiseListener.middleware,
      asyncSubmissionMiddleware,
      thunk
    )
  )
);

store.subscribe(() => {
    const state = store.getState();
    const persist = {
        auth: state.auth,
        cart: state.cart,
        total: state.total,
        search: state.search,
        callcenter: state.callcenter
    };

    window.localStorage.setItem('state', JSON.stringify(persist));
  });

export const promiseListener = reduxPromiseListener; // <---------- IMPORTANT

export default store