import { UPDATE_CART, CART_TOTAL_DELETE } from './actionTypes';

export const updateCart = cartProducts => dispatch => {
  let productQuantity = cartProducts.reduce((sum, p) => {
    sum += p.quantity;
    return sum;
  }, 0);

  let totalPrice = cartProducts.reduce((sum, p) => {
    sum += p.pricewithoffer * p.quantity;
    return sum;
  }, 0);

  let totalPriceWithoutOffer = cartProducts.reduce((sum, p) => {
    sum += p.pricewithoutoffer * p.quantity;
    return sum;
  }, 0);

  let cartTotal = {
    productQuantity,
    totalPrice,
    totalPriceWithoutOffer,
    currencyId: 'EUR',
    currencyFormat: '€'
  };

  dispatch({
    type: UPDATE_CART,
    payload: cartTotal
  });
};

export const cartTotalDelete = () => ({
  type: CART_TOTAL_DELETE
});
