import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import {loadCart, removeProduct, changeProductQuantity, removeCart} from '../../services/cart/actions';
import {cartTotalDelete, updateCart} from '../../services/total/actions';
import {formatPrice} from '../../services/util';

import CartProduct from './CartProduct';

import Collapse from '@material-ui/core/Collapse';
import {withTranslation} from "react-i18next";
import {isMobile} from "react-device-detect";

import {addCartGTM, removeCartGTM} from '../GTMComponent/GTMComponent';
import {modifyReservation} from '../../actions/reservations';
import {createDashboardAlert} from '../../actions/alerts';
import {Link} from "react-router-dom";


class Cart extends Component {
    static propTypes = {
        loadCart: PropTypes.func.isRequired,
        updateCart: PropTypes.func.isRequired,
        cartProducts: PropTypes.array.isRequired,
        newProduct: PropTypes.object,
        removeProduct: PropTypes.func,
        productToRemove: PropTypes.object,
        changeProductQuantity: PropTypes.func,
        productToChange: PropTypes.object
    };

    state = {
        isOpen: true
    };

    componentDidMount() {
        if (this.props.isModifying && this.props.cartProducts.length > 0) {
            // Borramos el carrito actual cuando se modifica una habitación
            const {removeCart, cartTotalDelete} = this.props;
            removeCart();
            cartTotalDelete();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.newProduct !== this.props.newProduct) if (this.props.isModifying && this.props.cartProducts.length > 0) {
        } else {
            this.addProduct(nextProps.newProduct);
        }

        if (nextProps.productToRemove !== this.props.productToRemove) {
            this.removeProduct(nextProps.productToRemove);
        }

        if (nextProps.productToChange !== this.props.productToChange) {
            this.changeProductQuantity(nextProps.productToChange);
        }
    }

    openFloatCart = () => {
        this.setState({isOpen: true});
    };

    closeFloatCart = () => {
        this.setState({isOpen: false});
    };

    openCart = () => {
        this.setState({isOpen: !(this.state.isOpen)});
    };

    addProduct = (product) => {
        const {cartProducts, updateCart} = this.props;
        // let productAlreadyInCart = false;

        product.reservation = this.props.search;
        product.quantity = 1;
        product.id = product.id + "-" + Date.now();

        // if (product.springclub) {
        //     product.pricewithoffer = product.pricewithoffer - (product.pricewithoffer * 0.05);
        // }

        product.pricewithoffer = Math.round((product.pricewithoffer) * 100) / 100;

        new addCartGTM(product);

        // cartProducts.forEach(cp => {
        //     if (cp.id === product.id) {
        //         // cp.quantity += product.quantity;
        //         productAlreadyInCart = true;
        //     }
        // });
        //
        // if (!productAlreadyInCart) {
        //     cartProducts.push(product);
        // }

        cartProducts.push(product);
        console.log("productos: ", product);

        updateCart(cartProducts);
        this.openFloatCart();
    };

    removeProduct = product => {
        const {cartProducts, updateCart} = this.props;

        const index = cartProducts.findIndex(p => p.id === product.id);
        if (index >= 0) {
            cartProducts.splice(index, 1);
            updateCart(cartProducts);
        }

        new removeCartGTM(product);
    };

    cartButton = (t) => {
        if (this.props.isModifying) {
            const {idRoomToChange} = this.props;
            return <Link to={'/myreservations/room/' + idRoomToChange}>
                <button onClick={() => this.proceedToModify(t)} className="final-btn">
                    {t('modification.to_modify')}
                </button>
            </Link>
        } else {
            return <button onClick={() => this.proceedToCheckout(t)} className="final-btn">
                {t('cart.reservation')}
            </button>
        }
    };

    proceedToModify = (t) => {
        const {idRoomToChange, createDashboardAlert, cartProducts, modifyReservation} = this.props;
        const {productQuantity} = this.props.cartTotal;

        if (!productQuantity) {
            alert(t('cart.add_product'));
        } else {
            modifyReservation(idRoomToChange, cartProducts[0]).then(data => {
                    if (data?.status === "OK") {
                        createDashboardAlert(t('modification.successfully_modified'), "success-msg");
                    }
                    else {
                        createDashboardAlert(t('ERROR') + ": " + data?.info, "error-msg");
                    }
                }
            );
        }
    };

    proceedToCheckout = (t) => {
        const {
            productQuantity,
        } = this.props.cartTotal;

        if (!productQuantity) {
            alert(t('cart.add_product'));
        } else {
            window.location.href = '/checkout/' + this.props.hotel;
        }
    };

    changeProductQuantity = changedProduct => {
        const {cartProducts, updateCart} = this.props;

        const product = cartProducts.find(p => p.id === changedProduct.id);
        product.quantity = changedProduct.quantity;
        if (product.quantity <= 0) {
            this.removeProduct(product);
        }
        updateCart(cartProducts);
    };

    render() {
        const {t} = this.props;
        const {cartTotal, cartProducts, removeProduct, changeProductQuantity} = this.props;
        const {auth} = this.props;
        let commisionView;
        let commission;

        if (this.props.isCheckoutView && auth.isAgency) {
            commisionView = true;
            commission = auth.agency.descuento;
        } else if (this.props.isCheckoutView && (auth.user?.rol === 'agent')) {
            commisionView = true;
            commission = auth.user.agency_commission;
        } else {
            commisionView = false
        }

        const products = cartProducts.map((p, index) => {
            return (
                <CartProduct product={p} removeProduct={removeProduct} changeProductQuantity={changeProductQuantity}
                             key={p.id} cartEdit={this.props.cartEdit} indexHab={index + 1}/>
            );
        });

        let classes = ['float-cart'];

        if (!!this.state.isOpen) {
            classes.push('float-cart--open');
        }

        // if(cartProducts.length === 0) {
        //     this.classes = ['float-cart'];
        // }

        let cartClasses = ['process-cart in-top opened'];
        if (!((isMobile || window.innerWidth < 1100) && cartProducts.length > 0)) {
            cartClasses = 'process-cart in-top opened empty-price';
        }

        return (
            <aside className="search-result-aside">
                <article className={cartClasses}>

                    <header className="process-cart-header" onClick={this.openCart}>
                        <div className="mobile-price-info">
                            <p className="site-color">{cartTotal.productQuantity}x {t('spring.room')}</p>
                            <p className="price">
                                <span
                                    className="old">{(Math.round((cartTotal.totalPriceWithoutOffer) * 100) / 100) + cartTotal.currencyFormat}</span>
                                <strong
                                    className="final">{(Math.round((cartTotal.totalPrice) * 100) / 100) + cartTotal.currencyFormat}</strong>
                            </p>
                        </div>
                        <h3 className="title primary-title-font">{t('cart.your_reservation')}</h3>
                    </header>


                    <Collapse in={this.state.isOpen} timeout="auto" unmountOnExit>
                        <div className="cart-content">
                            <a onClick={this.openCart}>
                                <div className="only-mobile dropdown-cart-header site-bg-light">
                                    <h5 className="title">{t('cart.your_reservation')}</h5>
                                </div>
                            </a>
                            <div className="dd rooms-list">
                                {products}
                                {!products.length && (
                                    <p>
                                        {t('cart.add_empty')} <br/>
                                    </p>
                                )}
                            </div>
                        </div>
                    </Collapse>


                    <div className="process-cart-content">
                        {(commisionView && !this.props.search.spring40) ?
                            <>
                                <div className="cart-price">
                                    <h5>{t('msg.pvp')}</h5>
                                    <p className="price">
                                        <strong className="final" title="final">
                                            {`${formatPrice(
                                                cartTotal.totalPrice,
                                                cartTotal.currencyId
                                            )}${cartTotal.currencyFormat}`}
                                        </strong>
                                    </p>
                                </div>

                                <div className="cart-price">
                                    <h5>{t('words.commission')} ({Math.round(commission)}%)</h5>
                                    <p className="price">
                                        <strong className="final" title="final">
                                            {((cartTotal.totalPrice * commission) / 100).toFixed(2)}{cartTotal.currencyFormat}
                                        </strong>
                                    </p>
                                </div>

                                <div className="cart-price">
                                    <h5>{t('cart.final_price')}</h5>
                                    <p className="price">
                                        <span className="old" title="sin descuento">
                                            {`${formatPrice(
                                                cartTotal.totalPrice,
                                                cartTotal.currencyId
                                            )}${cartTotal.currencyFormat}`}
                                        </span>
                                        <strong className="final" title="final">
                                            {(cartTotal.totalPrice - ((cartTotal.totalPrice * commission) / 100)).toFixed(2)}{cartTotal.currencyFormat}
                                        </strong>
                                    </p>
                                </div>
                            </>
                            :
                            <div className="cart-price">
                                <h5>{t('cart.final_price')}</h5>
                                <p className="price">
                                    {/*<span className="old" title="sin descuento">1.880€</span>*/}
                                    <strong className="final" title="final">
                                        {`${cartTotal.currencyFormat} ${formatPrice(
                                            cartTotal.totalPrice,
                                            cartTotal.currencyId
                                        )}`}
                                    </strong>
                                </p>
                            </div>
                        }
                    </div>

                    {this.props.buttonDisabled ? null :
                        <footer className="process-cart-footer">
                            {this.cartButton(t)}
                        </footer>
                    }
                </article>
            </aside>
        );
    }
}

const mapStateToProps = state => ({
    cartProducts: state.cart.products,
    newProduct: state.cart.productToAdd,
    productToRemove: state.cart.productToRemove,
    productToChange: state.cart.productToChange,
    cartTotal: state.total.data,
    search: state.search.payload,
    auth: state.auth,
    isModifying: state.reservations.modification?.isModifying,
    idRoomToChange: state.reservations.modification?.idRoomToChange
});

export default connect(
    mapStateToProps,
    {
        loadCart,
        updateCart,
        removeProduct,
        changeProductQuantity,
        removeCart,
        cartTotalDelete,
        modifyReservation,
        createDashboardAlert
    }
)(withTranslation('common')(Cart));
