import React, {useState} from "react";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import {useFormContext, Controller} from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import {useTranslation} from "react-i18next";

import "react-phone-input-2/lib/bootstrap.css";
import {checkoutGTM} from "../../../GTMComponent/GTMComponent";
import {useDispatch, useSelector} from "react-redux";
import {confirmBookingButtonUpdate} from "../../../../actions/checkout";
import Popup from "reactjs-popup";

function TranslatePoliticiesPages(language) {
    switch (language) {
        case "es":
            return process.env.REACT_APP_POLICIES_URL_ES;
            break;
        case "en":
            return process.env.REACT_APP_POLICIES_URL_EN;
            break;
        case "fr":
            return process.env.REACT_APP_POLICIES_URL_FR;
            break;
        case "nl":
            return process.env.REACT_APP_POLICIES_URL_NL;
            break;
        case "de":
            return process.env.REACT_APP_POLICIES_URL_DE;
            break;
        default:
            return process.env.REACT_APP_POLICIES_URL_ES;
            break;
    }
}

export default function RegisterForm(params) {

    const {t, i18n} = useTranslation('common');

    const dispatch = useDispatch();
    const {register, control} = useFormContext();

    const [country, setCountry] = useState();
    const [region, setRegion] = useState();
    const [phone, setPhone] = useState();
    const [endForm, setEndForm] = useState();

    const products = useSelector(state => state.cart.products);

    const cancellation_policies = TranslatePoliticiesPages(i18n.language);

    if (endForm) {
        new checkoutGTM(2);
    }

    return (
        <dd>
            <div className="column-inputs" styles="display:none;">
                {params.newuser ?
                    <div className="header">
                        <h3>{t('msg.new_in_spring')}</h3>
                        <p>{t('msg.first_reservation')}.</p>
                    </div>
                    :
                    null
                }
                <div className="column-inputs">
                    <div className="input-wrapper">
                        <label htmlFor="name">{t('words.first_name')} <span>({t('words.required')})</span></label>
                        <input type="text" id="firstName" name="firstName" ref={register} required/>
                    </div>

                    <div className="input-wrapper">
                        <label htmlFor="surname">{t('words.last_name')} <span>({t('words.required')})</span></label>
                        <input type="text" id="lastName" name="lastName" ref={register} required/>
                    </div>
                </div>

                <div className="column-inputs">
                    <div className="input-wrapper select-wrapper">
                        <label htmlFor="email">{t('words.country')} <span>({t('words.required')})</span></label>
                        <Controller
                            control={control}
                            name="country"
                            defaultValue=""
                            render={({onChange}) => (
                                <CountryDropdown
                                    id="country"
                                    name="country"
                                    required
                                    value={country}
                                    priorityOptions={["GB", "ES", "IE", "DE", "FR", "BE", "IT"]}
                                    onChange={(val) => {
                                        setCountry(val);
                                        onChange(val);
                                    }}
                                    ref={register}
                                    valueType="short"
                                />
                            )}
                        />
                    </div>

                    <div className="input-wrapper select-wrapper">
                        <label htmlFor="province">{t('words.region')} <span>({t('words.required')})</span></label>
                        <Controller
                            control={control}
                            name="region"
                            defaultValue=""
                            render={({onChange}) => (
                                <RegionDropdown
                                    countryValueType="short"
                                    id="region"
                                    name="region"
                                    required
                                    country={country}
                                    value={region}
                                    onChange={(val) => {
                                        setRegion(val);
                                        onChange(val);
                                    }}
                                    ref={register}
                                />
                            )}
                        />

                    </div>
                </div>

                {/*<div className="column-inputs">*/}
                {/*    <div className="input-wrapper">*/}
                {/*        <label htmlFor="dni">{t('words.passport_type')} </label>*/}
                {/*        <input type="text" id="passport" name="passport" ref={register}/>*/}
                {/*    </div>*/}

                {/*    <div className="input-wrapper">*/}
                {/*        <label htmlFor="birthday">{t('words.birthday')} <span>({t('words.required')})</span></label>*/}
                {/*        <input type="date" id="birthday" name="birthday" ref={register} required/>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="column-inputs">
                    <div className="input-wrapper phone-wrapper">
                        <div className="input-wrapper">
                            <label htmlFor="phone">{t('words.mobile_phone')}
                                <span>({t('words.required')})</span></label>
                            <Controller
                                control={control}
                                name="phone"
                                defaultValue=""
                                render={({onChange}) => (
                                    <PhoneInput
                                        id="phone"
                                        name="phone"
                                        ref={register}
                                        country={'gb'}
                                        preferredCountries={['ie', 'es', 'gb']}
                                        value={phone}
                                        onChange={p => {
                                            setPhone(p);
                                            onChange(p);
                                        }}
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>

                <div className="column-inputs">
                    <div className="input-wrapper checkbox-group">
                        <input
                            type="checkbox"
                            value="true"
                            id="privacy_policy"
                            name="privacy_policy"
                            required
                            ref={register}
                        />
                        <label htmlFor="privacy_policy"><a
                            href={i18n.language === 'es' ? "https://springhoteles.com/spring-hoteles/politica-de-privacidad/" : "https://springhoteles.com/en/spring-hoteles/politica-de-privacidad/"} target={"_blank"}>
                            {t('msg.privacy_policy')}
                        </a><span>({t('words.required')})</span></label>
                    </div>
                    <div className="input-wrapper checkbox-group">
                        <input
                            type="checkbox"
                            value="true"
                            id="user_acceptance"
                            name="user_acceptance"
                            required
                            ref={register}
                            onChange={(val) => {
                                setEndForm(true);
                                dispatch(confirmBookingButtonUpdate(true));
                            }}
                        />
                        <label htmlFor="user_acceptance"><a
                            href={cancellation_policies} target={"_blank"}>
                            {t('msg.user_acceptance')}
                        </a><span>({t('words.required')})</span>
                        </label>
                    </div>
                    {products[0].christmas_offer &&
                        <div className="input-wrapper checkbox-group christmas-offer">
                            <input
                                type="checkbox"
                                value="true"
                                id="privacy_policy"
                                name="privacy_policy"
                                required
                                ref={register}
                            />
                            <Popup modal trigger={
                                <label><u>{t('msg.christmas_offer_text')}</u><span>({t('words.required')})</span></label>
                            }>
                                {close => (
                                    <div id="modal_standar_selected"
                                         className="standar-selected-modal center-text">
                                        <h3><strong>{t('result.header_christmas_modal')}</strong></h3>
                                        <p>
                                            {t("result.text_christmas_modal")}
                                        </p>
                                        <div className="column-btns">
                                            <button className="btn btn-primary full-weidth"
                                                    onClick={() => {
                                                        close();
                                                    }}>
                                                {t('result.understood')}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Popup>
                        </div>}
                </div>
            </div>
        </dd>
    )
}