import React, {useState} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import {confirmBookingButtonUpdate} from "../../../../actions/checkout";
import Popup from 'reactjs-popup';

import {checkoutGTM} from "../../../GTMComponent/GTMComponent";

import CreditCardPayment from "../paymentForm";

function TranslatePoliticiesPages(language) {
    switch (language) {
        case "es":
            return process.env.REACT_APP_POLICIES_URL_ES;
            break;
        case "en":
            return process.env.REACT_APP_POLICIES_URL_EN;
            break;
        case "fr":
            return process.env.REACT_APP_POLICIES_URL_FR;
            break;
        case "nl":
            return process.env.REACT_APP_POLICIES_URL_NL;
            break;
        case "de":
            return process.env.REACT_APP_POLICIES_URL_DE;
            break;
        default:
            return process.env.REACT_APP_POLICIES_URL_ES;
            break;
    }
}

export default function UserForm() {

    const {t, i18n} = useTranslation('common');

    const dispatch = useDispatch();

    const callCentreActive = useSelector(state => state.callcenter.callCenterActive);
    let user = useSelector(state => state.auth.user);
    const customer = useSelector(state => state.callcenter.user);

    const cancellation_policies = TranslatePoliticiesPages(i18n.language);


    if (callCentreActive) {
        user = customer;
    }

    const [country, setCountry] = useState();
    const [region, setRegion] = useState();
    const [phone, setPhone] = useState(user.mobile_phone_no_field);
    const [endForm, setEndForm] = useState();

    const hotel = useSelector(state => state.search.payload.hotel);

    const {register, control} = useFormContext(); // retrieve all hook methods

    new checkoutGTM(1);

    if (endForm) {
        new checkoutGTM(2);
    }

    const products = useSelector(state => state.cart.products);

    return (
        <React.Fragment>
            <dl className="checkout-box">
                <dd>
                    <div className="column-inputs">
                        <div className="input-wrapper">
                            <label htmlFor="email">{t('words.email')} <span>({t('words.required')})</span></label>
                            <input
                                type="text"
                                id="email"
                                name="email"
                                defaultValue={user.e_mail}
                                disabled
                                ref={register}
                            />
                        </div>
                    </div>
                </dd>

            </dl>

            <dd>
                <div className="column-inputs" styles="display:none;">

                    <div className="column-inputs">
                        <div className="input-wrapper">
                            <label htmlFor="name">{t('words.first_name')} <span>({t('words.required')})</span></label>
                            <input type="text" id="firstName" name="firstName" defaultValue={user.first_name}
                                   ref={register} required/>
                        </div>

                        <div className="input-wrapper">
                            <label htmlFor="surname">{t('words.last_name')} <span>({t('words.required')})</span></label>
                            <input type="text" id="lastName" name="lastName" defaultValue={user.surname} ref={register}
                                   required/>
                        </div>
                    </div>

                    <div className="column-inputs">
                        <div className="input-wrapper select-wrapper">
                            <label htmlFor="email">{t('words.country')} <span>({t('words.required')})</span></label>
                            <Controller
                                control={control}
                                name="country"
                                defaultValue=""
                                render={({onChange}) => (
                                    <CountryDropdown
                                        id="country"
                                        name="country"
                                        required
                                        value={country}
                                        priorityOptions={["GB", "ES", "IE", "DE", "FR", "BE", "IT"]}
                                        onChange={(val) => {
                                            setCountry(val);
                                            onChange(val);
                                        }}
                                        ref={register}
                                        valueType="short"
                                    />
                                )}
                            />
                        </div>

                        <div className="input-wrapper select-wrapper">
                            <label htmlFor="province">{t('words.region')} <span>({t('words.required')})</span></label>
                            <Controller
                                control={control}
                                name="region"
                                defaultValue=""
                                render={({onChange}) => (
                                    <RegionDropdown
                                        countryValueType="short"
                                        id="region"
                                        name="region"
                                        required
                                        country={country}
                                        value={region}
                                        onChange={(val) => {
                                            setRegion(val);
                                            onChange(val);
                                        }}
                                        ref={register}
                                    />
                                )}
                            />

                        </div>
                    </div>

                    <div className="column-inputs">
                        <div className="input-wrapper phone-wrapper">
                            <div className="input-wrapper">
                                <label htmlFor="phone">{t('words.mobile_phone')}
                                    <span>({t('words.required')})</span></label>
                                <Controller
                                    control={control}
                                    name="phone"
                                    defaultValue=""
                                    required
                                    render={({onChange}) => (
                                        <PhoneInput
                                            id="phone"
                                            name="phone"
                                            ref={register}
                                            country={'gb'}
                                            preferredCountries={['ie', 'es', 'gb']}
                                            value={phone}
                                            onChange={p => {
                                                setPhone(p);
                                                onChange(p);
                                            }}
                                            inputProps={{
                                                name: 'phone',
                                                required: true,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="column-inputs">
                        <div className="input-wrapper">
                            <input
                                type="checkbox"
                                value="true"
                                id="privacy_policy"
                                name="privacy_policy"
                                required
                                ref={register}
                            />
                            <label htmlFor="privacy_policy"><a
                                href={i18n.language === 'es' ? "https://springhoteles.com/spring-hoteles/politica-de-privacidad/" : "https://springhoteles.com/en/spring-hoteles/politica-de-privacidad/"}
                                target={"_blank"}>
                                {t('msg.privacy_policy')}
                            </a><span>({t('words.required')})</span></label>
                        </div>
                        <div className="input-wrapper">
                            <input
                                type="checkbox"
                                value="true"
                                id="user_acceptance"
                                name="user_acceptance"
                                required
                                ref={register}
                                onChange={(val) => {
                                    setEndForm(true);
                                    dispatch(confirmBookingButtonUpdate(true));
                                }}
                            />
                            <Popup modal trigger={
                                <label htmlFor="user_acceptance"><u>
                                    {t('msg.user_acceptance')}
                                </u><span>({t('words.required')})</span>
                                </label>
                            }>
                                {close => (
                                    <div id="modal_standar_selected"
                                         className="standar-selected-modal center-text">
                                        {/* TODO Traduccion */}
                                        <h3><strong>Políticas de Reserva y Cancelación.</strong></h3>
                                        <ul className={"left-text"}>
                                            <li>Las reservas deberán cancelarse antes de las 11:00 de la mañana del día
                                                anterior a la llegada prevista, para evitar el cobro de la primera noche
                                                de gastos (+impuestos).
                                            </li>
                                            <li>Usted garantiza la reserva con su tarjeta de crédito. No se requiere
                                                depósito. Pago directo a la llegada. (Excepto condiciones especiales).
                                            </li>
                                            <li>Gastos de no-show: primera noche más impuestos.</li>
                                            <li>Hora de llegada: 15:00.</li>
                                            <li>Hora de salida: 12:00.</li>
                                            <li>Todos los precios son válidos excepto error de transcripción o
                                                informático, en cuyo caso se declina toda responsabilidad de mantener
                                                dichos precios.
                                            </li>
                                            <li>Las reservas deberán modificarse y cancelarse a través de nuestra página
                                                web www.springhoteles.com.
                                            </li>
                                        </ul>
                                        <div className="column-btns">
                                            <button className="btn btn-primary full-weidth"
                                                    onClick={() => {
                                                        close();
                                                    }}>
                                                {t('result.understood')}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Popup>
                        </div>
                        {products[0].christmas_offer &&
                            <div className="input-wrapper christmas-offer">
                                <input
                                    type="checkbox"
                                    value="true"
                                    id="privacy_policy"
                                    name="privacy_policy"
                                    required
                                    ref={register}
                                />
                                <Popup modal trigger={
                                    <label><u>{t('msg.christmas_offer_text')}</u><span>({t('words.required')})</span></label>
                                }>
                                    {close => (
                                        <div id="modal_standar_selected"
                                             className="standar-selected-modal center-text">
                                            <h3><strong>{t('result.header_christmas_modal')}</strong></h3>
                                            <p>
                                                {t("result.text_christmas_modal")}
                                            </p>
                                            <div className="column-btns">
                                                <button className="btn btn-primary full-weidth"
                                                        onClick={() => {
                                                            close();
                                                        }}>
                                                    {t('result.understood')}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </div>}

                    </div>
                </div>
            </dd>

            <CreditCardPayment/>

        </React.Fragment>
    )
};