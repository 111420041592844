import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import queryString from "query-string";

import Box from '@material-ui/core/Box';

import {dispatchChaining, fetchExtraProducts} from '../../services/shelf/actions';
import {createSearch} from "../../actions/search";


import Spinner from '../Spinner';
import RoomList from "./RoomList";
import RoomListAgency from "./RoomListAgency";
import Cart from "../Cart";
import i18next from "i18next";
import {impressionsGTM} from "../GTMComponent/GTMComponent";


class EngineResult extends Component {

    static propTypes = {
        fetchProducts: PropTypes.func,
        dispatchChaining: PropTypes.func,
        fetchExtraProducts: PropTypes.func,
        products: PropTypes.array.isRequired,
        filters: PropTypes.array,
        sort: PropTypes.string
    };

    state = {
        productsReceived: 0,
        countryReceived: undefined,
    };

    constructor(props) {
        super(props);
    }

    searchByParams(query) {
        const query_parsed = queryString.parse(query);
        query_parsed.lang = i18next.language;

        this.props.createSearch(query_parsed);

        if (this.props.isAgency && this.props.isAuthenticated) {
            this.props.dispatchChaining(query_parsed, this.props.agencyID);
        } else {
            this.props.dispatchChaining(query_parsed, null);
        }

        // this.handleFetchExtraProducts(query_parsed); // Activación de los extras por api
    }

    componentDidMount() {
        if (this.props.search_query !== "") {
            this.searchByParams(this.props.search_query);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    componentWillReceiveProps(nextProps) {
        // if (nextProps.country !== this.props.country) {
        //     if (this.props.search_query !== "") {
        //         this.searchByParams(nextProps.country, this.props.search_query);
        //     }
        // }
    }

    handleFetchExtraProducts = (
        search = this.props.search
    ) => {
        this.props.fetchExtraProducts(search);
    };

    render() {
        const {products} = this.props;
        const {productsclub} = this.props;
        const {productsIsDone} = this.props;
        const {productsReceived} = this.state;

        if (products.length > productsReceived) {
            this.setState({'productsReceived': products.length});
            new impressionsGTM(products, this.props.search);
        }

        return (
            <React.Fragment>
                {!productsIsDone && <Spinner/>}
                <div className="search-result container">
                    <div className="search-result-content">
                        <Box component="div" className="search-result-list" display="inline">
                            {this.props.isAgency && this.props.isAuthenticated ?
                                <RoomListAgency products={products} hotel={this.props.hotel}/>
                                :
                                <>
                                    {products.length > 1 &&
                                        <RoomList products={products} productsclub={productsclub}
                                                  hotel={this.props.hotel}/>
                                    }
                                </>
                            }
                        </Box>
                        <Box component="div" display="inline">
                            <Cart hotel={this.props.hotel} isCheckoutView={this.props.isCommissionView}/>
                        </Box>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    products: state.shelf.products,
    productsclub: state.shelf.productsClub,
    extraproducts: state.shelf.extraproducts,
    productsIsDone: state.shelf.isDone,
    filters: state.filters.items,
    sort: state.sort.type,
    search: state.search.payload,
    uid: state.auth.user?.id,
    isAuthenticated: state.auth.isAuthenticated,
    isAgency: state.auth.isAgency,
    agencyID: state.auth.agency?.pms_number,
    isCommissionView: state.agency?.isCommissionView
});

const mapDispatchToProps = {fetchExtraProducts, dispatchChaining, createSearch};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EngineResult);
