import React from 'react';

import Product from '../Product';
import {useSelector} from "react-redux";

const ProductList = ({products, translate_room, productsclub}) => {

    let arrival = new Date(useSelector(state => state.search.payload.arrival));
    let departure = new Date(useSelector(state => state.search.payload.departure));
    let christmas_offer_date_1 = new Date(arrival.getFullYear(), process.env.REACT_APP_CHRISTMAS_MONTH-1, process.env.REACT_APP_CHRISTMAS_DAY_1);
    let christmas_offer_date_2 = new Date(arrival.getFullYear(), process.env.REACT_APP_CHRISTMAS_MONTH-1, process.env.REACT_APP_CHRISTMAS_DAY_2);

    return products.map((p, indexproduct) => {
        let index = indexproduct;
        p.translation_code = translate_room;
        productsclub[index].translation_code = translate_room;
        productsclub.map((pc, indexclub) => {
            if (pc.boardtype === p.boardtype) {
                index = indexclub;
            }
        })
        if((arrival <= christmas_offer_date_1 && departure >= christmas_offer_date_1) ||
        (arrival <= christmas_offer_date_2 && departure >= christmas_offer_date_2) ||
            (arrival <= christmas_offer_date_1 && departure >= christmas_offer_date_2)){
            p.christmas_offer = true;
            productsclub[index].christmas_offer = true;
        }
        return <Product product={p} translate_room={p.translation_code} productclub={productsclub[index]} key={p.id}/>;
    });
};

export default ProductList;