import { SEARCH, SEARCH_SUCCESS, SEARCH_FAILURE } from '../actions/types.js'

export default function search(state = {}, action = {}) {
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        searching: true,
        payload: action.payload
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        searching: false,
      };
    case SEARCH_FAILURE:
      return {
        ...state,
        searching: false
      };
    default:
      return state
  }
}