const THNScript = (hotelname, callback) => {

    const getUrl = (hotelname) => {
        if(hotelname === "arona") {
            return 'https://www.thehotelsnetwork.com/js/hotel_price_widget.js?hotel_id=1089908&property_id=1014420&account_key=003c10d8946f00c91d6dcbc982947c88'
        }
        else if(hotelname === "bitacora") {
            return 'https://www.thehotelsnetwork.com/js/hotel_price_widget.js?hotel_id=1088937&property_id=1014422&account_key=003c10d8946f00c91d6dcbc982947c88'
        }
        else {
            return 'https://www.thehotelsnetwork.com/js/hotel_price_widget.js?hotel_id=1089909&property_id=1014421&account_key=003c10d8946f00c91d6dcbc982947c88'
        }
    };

    const existingScript = document.getElementById('thnScript');
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = getUrl(hotelname);
        script.id = 'thnScript';
        document.body.appendChild(script);
        script.onload = () => {
            if (callback) callback();
        };
    }
    if (existingScript && callback) callback();
};

export default THNScript;