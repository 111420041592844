import {FETCH_EXTRA_PRODUCTS, FETCH_PRODUCTS, FETCH_PRODUCTS_CLUB, UPDATE_PRODUCTS} from './actionTypes';
import axios from 'axios';

const IP_DJANGO = process.env.REACT_APP_DJANGO_IP;
const PORT_DJANGO = process.env.REACT_APP_DJANGO_PORT;
const HTTP_PROTOCOL = process.env.REACT_APP_HTTP_PROTOCOL;

const BASEURL = HTTP_PROTOCOL + '://' + IP_DJANGO + ':' + PORT_DJANGO;

const createUrl = (search, club, agency) => {

    if (search?.hotel === undefined) {
        // Al no estar el hotel como url param obtenemos el hotel de la url
        search.hotel = window.location.href.split('/').pop().split('?')[0];
    }

    if (search !== undefined) {
        let params = {
            hotel: search.hotel,
            arrival: search.arrival,
            departure: search.departure,
            resident: search.resident,
            adults: search.adults,
            children: search.room_num_childs,
            children0: search.room_num_childs_0,
            children1: search.room_num_childs_1,
            children2: search.room_num_childs_2,
            rooms: search.rooms,
            boards: search.boards,
            club: club,
            agency: agency
        };
        if (search.promotion !== undefined) {
            params.promotion = search.promotion;
        }
        if (search.lcode !== undefined) {
            params.lcode = search.lcode;
        }
        if (search.spring40 !== undefined) {
            params.spring40 = search.spring40;
            params.idSF = search.idSF;
        }

        return axios.get(BASEURL + '/en/api/2/search/', {params})
    }
};


const createExtrasUrl = (date, adults, childs) => {
    return axios.get(BASEURL + '/fareharbor-api/booking/', {
        params: {
            date: date,
            adults: adults,
            childs: childs,
            company_name: 'elprado-eur',
            itempk: '20580',
        }
    });
};


export const fetchExtraProducts = (search, callback) => dispatch => {

    if (search !== undefined) {
        let arrival_date = new Date(search.arrival);
        let departure_date = new Date(search.departure);
        let date = new Date();

        if ((departure_date.getDate() - arrival_date.getDate()) > 1) {
            // Si se queda más de un día le ofrecemos la excursión al día siguiente de la llegada
            date.setDate(arrival_date.getDate() + 1);
            date = date.toISOString().split('T')[0]


            return createExtrasUrl(date, search.adults, search.room_num_childs)
                .then(res => {
                    let extraProducts = res.data.availabilities;

                    if (!!callback) {
                        callback();
                    }

                    return dispatch({
                        type: FETCH_EXTRA_PRODUCTS,
                        payload: extraProducts
                    });
                })
                .catch(err => {
                    console.log('Could not fetch extra products. Try again later.', err);
                });
        }
    }
};

const updateProducts = (products) => ({type: FETCH_PRODUCTS, payload: products});
const updateProductsClub = (productsClub) => ({type: FETCH_PRODUCTS_CLUB, payload: productsClub});
const updateDone = () => ({type: UPDATE_PRODUCTS});


const loadProducts = (search, agency) => async (dispatch) => {
    const response = await createUrl(search, false, agency);

    let products = JSON.parse(response.data).products;
    return dispatch(updateProducts(products));
};

const loadProductsClub = (search) => async (dispatch) => {
    const response = await createUrl(search, true);

    let productsClub = JSON.parse(response.data).products;
    return dispatch(updateProductsClub(productsClub));
};


export const dispatchChaining = (search, agency) => async (dispatch, getState) => {
    if (agency) {
        await Promise.all([
            dispatch(loadProducts(search, agency))
        ]);
    } else {
        await Promise.all([
            dispatch(loadProducts(search)),
            dispatch(loadProductsClub(search))
        ]);
    }

    return dispatch(updateDone());
};