import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import Collapse from '@material-ui/core/Collapse';
import {isBrowser} from "react-device-detect";

import LoginPage from "../../../Login/LoginPage";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import Spinner from "../../../Spinner";

import {getReservationDetail} from "../../../../actions/reservations";
import CardDetail from "./CardDetail";
import {MainMessage} from "../../CallCenterUtils";


export class BookingDetail extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
    };

    state = {
        isOpen: false
    };

    componentDidMount() {
        this.props.getReservationDetail(this.props.match.params.idreservation);
        if (isBrowser) {
            this.setState({isOpen: true});
        }
    }

    openCollapse = () => {
        this.setState({isOpen: !(this.state.isOpen)});
    };

    render() {
        const {t} = this.props;
        const {isAuthenticated} = this.props.auth;
        const reservation = this.props.reservation;
        const userdata = this.props.reservation?.userdata;

        const isCancelled = () => {
            if (reservation?.status === 'canceled') {
                return true;
            } else {
                return false;
            }
        };

        let pms_keys;
        if (this.props.isLoaded && reservation) {
            pms_keys = Array.from(reservation?.rooms.values(), value => value['pms_resid_key']).join(", ");
        }

        console.log(reservation);

        return (
            <>
                {this.props.isLoaded ? null : <Spinner/>}
                {isAuthenticated ?
                    <>
                        <Header hotel="spring"/>

                        {this.props.callcenter.callCenterActive && <MainMessage/>}

                        <main className="container apriv-page">
                            <header className="apriv-header small-margin">
                                <a href="/myreservations" className="back-link">
                                    <span>{t('msg.back_reservations')}</span>
                                </a>
                            </header>


                            <section className="booking-view-wrapper">
                                <header>
                                    <h1 className="title">{t('cart.your_reservation')}: {pms_keys}</h1>
                                </header>

                                <div className="mobile-price-info">
                                    <div className="booking-view-price">
                                        <p className="title">{t('cart.final_price')}</p>
                                        <p className="price"><strong className="final"
                                                                     title="final">{reservation?.price}€</strong></p>
                                    </div>
                                </div>

                                <h2 className="h3 title">{t('search.see rooms')}:</h2>
                                <div className="booking-view-contain">
                                    <div className="booking-view-info">
                                        <div className="booking-view-rooms">
                                            {reservation?.rooms.map(item => <CardDetail room={item} isCancelled/>)}

                                            {/*<a href="https://booking.springhoteles.com/" className="add-new-room">*/}
                                            {/*    <span>Añadir habitación</span>*/}
                                            {/*</a>*/}
                                        </div>
                                        <div className="booking-view-data">
                                            <h2 className="h3 title">{t('msg.reservation_data')}:</h2>
                                            <div className="form-style only-view">
                                                <div className="inputs-group">
                                                    <div className="column-inputs">
                                                        <div className="input-wrapper">
                                                            <label htmlFor="email">{t('words.email')}</label>
                                                            <input type="email" id="email" name="email"
                                                                   value={userdata?.email} disabled/>
                                                        </div>
                                                        <div className="input-wrapper">
                                                            <label htmlFor="phone">{t('words.mobile_phone')}</label>
                                                            <input type="text" id="phone" name="phone"
                                                                   value={"+" + userdata?.phone} disabled/>
                                                        </div>
                                                    </div>
                                                    <div className="column-inputs">
                                                        <div className="input-wrapper">
                                                            <label htmlFor="name">{t('words.first_name')}</label>
                                                            <input type="text" id="name" name="name"
                                                                   value={userdata?.first_name}
                                                                   disabled/>
                                                        </div>
                                                        <div className="input-wrapper">
                                                            <label htmlFor="surname">{t('words.last_name')}</label>
                                                            <input type="text" id="surname" name="surname"
                                                                   value={userdata?.last_name} disabled/>
                                                        </div>
                                                    </div>
                                                    <div className="column-inputs">
                                                        <div className="input-wrapper">
                                                            <label htmlFor="country">{t('words.country')}</label>
                                                            <input type="text" id="country" name="country"
                                                                   value={userdata?.country}
                                                                   disabled/>
                                                        </div>
                                                        <div className="input-wrapper">
                                                            <label htmlFor="province">{t('words.region')}</label>
                                                            <input type="text" id="province" name="province"
                                                                   value={userdata?.region}
                                                                   disabled/>
                                                        </div>
                                                    </div>
                                                    <div className="column-inputs">
                                                        {/*<div className="input-wrapper only-view-phone">*/}
                                                        {/*    <label htmlFor="prefix">Prefijo</label>*/}
                                                        {/*    <input type="text" id="prefix" name="prefix" value="+34"*/}
                                                        {/*           disabled/>*/}

                                                        {/*    <label htmlFor="phone">Teléfono</label>*/}
                                                        {/*    <input type="text" id="phone" name="phone" value="615657441"*/}
                                                        {/*           disabled/>*/}
                                                        {/*</div>*/}
                                                        <div className="input-wrapper">
                                                            <label
                                                                htmlFor="booking_date">{t('msg.reservation_date')}</label>
                                                            <input type="date" id="booking_date" name="booking_date"
                                                                   value={reservation?.reserved_datetime.split("T")[0]}
                                                                   disabled/>
                                                        </div>

                                                        <div className="input-wrapper">
                                                            <label
                                                                htmlFor="booking_date">{t('msg.reservation_date_update')}</label>
                                                            <input type="date" id="booking_date" name="booking_date"
                                                                   value={reservation?.updated_datetime.split("T")[0]}
                                                                   disabled/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <aside className="booking-view-aside-wrapper">
                                        <div className="booking-view-aside">
                                            <header className="booking-view-aside-header" onClick={this.openCollapse}>
                                                <h3 className="title primary-title-font">{t('msg.reservation_manage')}</h3>
                                            </header>

                                            <Collapse in={this.state.isOpen} timeout="auto" unmountOnExit>

                                                <div className="booking-view-aside-content">
                                                    <ul className="booking-edit-options">

                                                        {/*<li><a href="#update_date_booking">Modificar fechas</a>*/}
                                                        {/*</li>*/}

                                                        {/*<li><a href="/files/bookings/booking_3070759311.pdf">Descargar*/}
                                                        {/*    reserva en PDF</a></li>*/}
                                                        {/*<li><a href="#">Compartir por e-mail</a></li>*/}

                                                        {/*{isCancelled() ? null :*/}
                                                        {/*    <>*/}
                                                        {/*        <li><a>{t('msg.reservation_cancel')}</a></li>*/}
                                                        {/*    </>*/}
                                                        {/*}*/}
                                                        <li><a href="https://springhotels.oceanbooking.com/">{t('flights.search')}</a></li>
                                                    </ul>
                                                </div>

                                            </Collapse>

                                            <footer className="booking-view-aside-footer">
                                                <div className="booking-view-price">
                                                    <p className="title">{t('cart.final_price')}</p>
                                                    <p className="price"><strong className="final"
                                                                                 title="final">{reservation?.price}€</strong>
                                                    </p>
                                                </div>

                                                {/*<a href="#" className="booking-view-match-price">*/}
                                                {/*    <span>Iguala la tarifa y ahorra 325,12€</span>*/}
                                                {/*</a>*/}

                                            </footer>

                                        </div>
                                    </aside>
                                </div>
                            </section>
                        </main>

                        <Footer hotel="spring"/>
                    </>
                    :
                    <LoginPage/>
                }
            </>

        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    isAgency: state.auth.isAgency,
    reservation: state.reservations.detail,
    isLoaded: state.reservations.isLoaded,
    callcenter: state.callcenter
});

export default connect(
    mapStateToProps, {getReservationDetail}
)(withRouter(withTranslation('common')(BookingDetail)));