import React from 'react';
import TagManager from "react-gtm-module";
import store from "../../store";


function getCoupon() {
    const storeRedux = store.getState();
    return storeRedux.search.payload.promotion;
}

function getCartProducts() {
    const storeRedux = store.getState();
    return storeRedux.cart.products;
}

function getTotalPrice() {
    const storeRedux = store.getState();
    return storeRedux.total.totalPrice;
}

function getUserData() {
    const storeRedux = store.getState();

    let data = {};

    if (storeRedux.auth.isAuthenticated && storeRedux.auth.isAgency) {
        data['userType'] = 'agency';
        data['userID'] = storeRedux.auth.agency.codigo_acceso;
        data['country'] = storeRedux.auth.agency.country;
        data['region'] = '';
    } else if (storeRedux.auth.isAuthenticated && !storeRedux.auth.isAgency) {
        if (storeRedux.auth.user.rol === "agent") {
            data['userType'] = 'agent';
            data['userID'] = storeRedux.auth.user.id_salesforce;
            data['country'] = '';
            data['region'] = '';
        }
        else {
            data['userType'] = 'user';
            data['userID'] = storeRedux.auth.user.no_field;
            data['country'] = storeRedux.auth.user.country_region_code;
            data['region'] = storeRedux.auth.user.region;
        }
    } else {
        data['userType'] = '';
        data['userID'] = '';
        data['country'] = '';
        data['region'] = '';
    }

    return data;
}

function getTransactionProducts(products, pms_reservations_str = '') {

    const transactionProducts = [];

    if (pms_reservations_str !== '') {
        pms_reservations_str = pms_reservations_str.split(', ');
    }

    products.map((product, index) => {
            let hotelname;
            switch (product.hotelcode) {
                case 1027:
                    hotelname = 'Spring Hotel Vulcano';
                    break;
                case 1029:
                    hotelname = 'Spring Arona Gran Hotel';
                    break;
                case 1026:
                    hotelname = 'Spring Hotel Bitácora';
                    break;
            }
            transactionProducts.push(
                {
                    'id': product.id.split('-')[0] + '-' + product.id.split('-')[1], // required string hotelcode
                    'name': product.roomText, // required string room name
                    'price': product.pricewithoffer.toString(), // required numeric
                    'quantity': product.quantity, // required numeric
                    'category': 'Rooms', // required string (Rooms | Extras | Flights)
                    'brand': hotelname, // required string (Spring Hotel Vulcano | Spring Hotel Bitácora | Spring Arona Gran Hotel)
                    'variant': product.boardtype, // required string boardtype

                    'roomcode': product.id.split('-')[1], // string room code name
                    'coupon': '', // string

                    'dimension1': '', // empty
                    'dimension2': '', // empty
                    'dimension3': product.reservation.arrival, // string checkin
                    'dimension4': product.reservation.departure, // string checkout
                    'dimension5': '',// empty
                    'dimension6': product.reservation.adults + '-' + product.reservation?.room_num_childs, // string (adults - childs - babies)
                    'dimension7': ((new Date(product.reservation.departure).getTime() - new Date(product.reservation.arrival).getTime()) / (1000 * 3600 * 24)).toString(), // numeric nights
                    'dimension8': product.ratecode, // string ratecode
                    'dimension9': product.reservation.resident,  // string resident
                    'dimension10': (pms_reservations_str !== '' ? pms_reservations_str[index] : pms_reservations_str),
                    'dimension11': product.id.split('-')[0], // id Hotel
                    'dimension12': '1', // id cadena hotelera

                    'dimension14': getUserData()['userType'], // usertype
                    'dimension15': getUserData()['userID'],  // userID

                    'skufull': product.id, // string
                    'pricewithoutoffer': product.pricewithoutoffer.toString(), // numeric
                    'springclub': product.springclub.toString(), // boolean
                    'availability': product.availability.toString(), // numeric
                }
            )
        }
    );
    return transactionProducts
}

export function successGTM(products, totalprice, reservationEngineNumber, status, pms_reservations_str) {
    const userData = getUserData();

    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
            'event': 'purchase',
            'ecommerce': {
                'currencyCode': 'EUR', // ISO4217
                'status': status ? status : '',
                'purchase': {
                    'dimension14': userData['userType'],
                    'dimension15': userData['userID'],
                    'user_country': userData['country'],
                    'user_city': userData['region'],
                    'actionField': {
                        'id': reservationEngineNumber.toString(), // string
                        'revenue': totalprice.toString(), // numeric transactionTotal
                        'tax': '0', // numeric
                        'coupon': '', // string
                    },
                    'products': getTransactionProducts(products, pms_reservations_str),
                },
            },
        },
    };

    if (process.env.REACT_APP_GTM_LOG === 'true') {
        console.log("successGTM", tagManagerArgs);
    }
    if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
        TagManager.dataLayer(tagManagerArgs);
    }
}


export function addCartGTM(product, search) {

    if(!isNaN(product?.pricewithoffer)) {

        const products = [product];

        const tagManagerArgs = {
            gtmId: process.env.REACT_APP_GTM_ID,
            dataLayer: {
                'event': 'addToCart',
                'ecommerce': {
                    'currencyCode': 'EUR', // ISO4217
                    'add': {
                        'products': getTransactionProducts(products)
                    }
                },
            },
        };

        if (process.env.REACT_APP_GTM_LOG === 'true') {
            console.log("addCartGTM", tagManagerArgs);
        }
        if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
            TagManager.dataLayer(tagManagerArgs);
        }
    }
}

export function removeCartGTM(product) {
    // Si no es vacío
    if(!(product && Object.keys(product).length === 0 && product.constructor === Object)) {
        const products = [product];

        const tagManagerArgs = {
            gtmId: process.env.REACT_APP_GTM_ID,
            dataLayer: {
                'event': 'removeFromCart',
                'ecommerce': {
                    'remove': {
                        'products': getTransactionProducts(products)
                    }
                },
            },
        };

        if (process.env.REACT_APP_GTM_LOG === 'true') {
            console.log("removeCartGTM", tagManagerArgs);
        }
        if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
            TagManager.dataLayer(tagManagerArgs);
        }
    }
}

export function impressionsGTM(products, search) {

    const list_name = "Search Results";

    const impressionsProducts = [];

    let productCount = 1;

    products.map(product => {
            let hotelname;
            switch (search.hotel) {
                case 'vulcano':
                    hotelname = 'Spring Hotel Vulcano';
                    break;
                case 'arona':
                    hotelname = 'Spring Arona Gran Hotel';
                    break;
                case 'bitacora':
                    hotelname = 'Spring Hotel Bitácora';
                    break;
            }
            product.boardtypes.map(board => {
                    impressionsProducts.push(
                        {
                            'id': board.id.split('-')[0] + '-' + board.id.split('-')[1], // required string hotelcode
                            'name': board.roomText, // required string room name
                            'price': board.pricewithoffer.toString(), // required numeric
                            'quantity': 1, // required numeric
                            'category': 'Rooms', // required string (Rooms | Extras | Flights)
                            'brand': hotelname, // required string (Spring Hotel Vulcano | Spring Hotel Bitácora | Spring Arona Gran Hotel)
                            'variant': board.boardtype, // required string boardtype

                            'roomcode': product.room, // string room code name
                            'coupon': '', // string
                            'stock': board.availability.toString(), // required numeric

                            'list': list_name,
                            'position': productCount,

                            'dimension1': '', // empty
                            'dimension2': '', // empty
                            'dimension3': search.arrival, // string checkin
                            'dimension4': search.departure, // string checkout
                            'dimension5': '',// empty
                            'dimension6': search.adults + '-' + search.room_num_childs, // string (adults - childs - babies)
                            'dimension7': ((new Date(search.departure).getTime() - new Date(search.arrival).getTime()) / (1000 * 3600 * 24)).toString(), // numeric nights
                            'dimension8': board.ratecode, // string rate
                            'dimension9': search.resident,  // string resident
                            'dimension10': '', // pms Key
                            'dimension11': board.id.split('-')[0], // id Hotel
                            'dimension12': '1', // id cadena hotelera

                            'dimension14': getUserData()['userType'], // usertype
                            'dimension15': getUserData()['userID'],  // userID

                            'skufull': board.id, // string
                        }
                    );
                    productCount += 1;
                }
            )
        }
    );

    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
            'event': 'impressions',
            'ecommerce': {
                'currencyCode': 'EUR', // ISO4217
                'impressions': impressionsProducts,
            },
        },
    };

    if (process.env.REACT_APP_GTM_LOG === 'true') {
        console.log("impressionsGTM", tagManagerArgs);
    }
    if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
        TagManager.dataLayer(tagManagerArgs);
    }
}

export function checkoutGTM(step) {

    let step_option;
    // eslint-disable-next-line default-case
    switch (step) {
        case 1:
            step_option = 'Email Introducido';
            break;
        case 2:
            step_option = 'Datos Personales';
            break;
        case 3:
            step_option = 'Login';
            break;
        case 4:
            step_option = 'Pago';
            break;
        case 5:
            step_option = 'Formulario Enviado';
            break;
    }

    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
            'event': 'checkout',
            'ecommerce': {
                'currencyCode': 'EUR', // ISO4217
                'checkout': {
                    'actionField': {
                        'step': step,
                        'option': step_option
                    },
                    'products': getTransactionProducts(getCartProducts())
                },
            },
        },
    };

    if (process.env.REACT_APP_GTM_LOG === 'true') {
        console.log("checkoutGTM", tagManagerArgs);
    }
    if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
        TagManager.dataLayer(tagManagerArgs);
    }
}

export function detailGTM(product, search) {

    let products = product.boardtypes;
    products.map(product => {
        product['reservation'] = search;
        product['springclub'] = 'false';
    });

    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
            'event': 'detail',
            'ecommerce': {
                'detail': {
                    'actionField': {
                        'list': 'Disponibilidad',
                    },
                    'products': getTransactionProducts(products)
                },
            },
        },
    };

    if (process.env.REACT_APP_GTM_LOG === 'true') {
        console.log("detailGTM", tagManagerArgs);
    }
    if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
        TagManager.dataLayer(tagManagerArgs);
    }
}

export function loginGTM() {

    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
            'event': 'login',
            'user': {
                'login': {
                    'actionField': {'user_id': getUserData()['userID']},
                },
            },
        },
    };

    if (process.env.REACT_APP_GTM_LOG === 'true') {
        console.log("loginGTM", tagManagerArgs);
    }
    if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
        TagManager.dataLayer(tagManagerArgs);
    }
}

export function logoutGTM() {

    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
            'event': 'logout',
            'user': {
                'logout': {
                    'actionField': {'user_id': getUserData()['userID']},
                },
            },
        },
    };

    if (process.env.REACT_APP_GTM_LOG === 'true') {
        console.log("logoutGTM", tagManagerArgs);
    }
    if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
        TagManager.dataLayer(tagManagerArgs);
    }
}

export function canceledRoomGTM(idReservation, idRoom, idPMS, price) {

    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
            'event': 'refund',
            'ecommerce': {
                'refund': {
                    'actionField': {'id': idReservation},
                    'products': [
                        {
                            'id': idRoom,
                            'quantity': 1,
                            'price': price,
                            'pms_id': idPMS,
                        }
                    ]
                },
            },
        },
    };

    if (process.env.REACT_APP_GTM_LOG === 'true') {
        console.log("canceledRoomGTM", tagManagerArgs);
    }
    if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
        TagManager.dataLayer(tagManagerArgs);
    }
}