import React from "react";

import {SearchBox} from "../../../SearchBox/SearchBox";

export default function ModifyAll(pmskey, t) {

    return (
        <div className="update-booking">
            <div className="center-text">
                <h2 className="h1">{t('modification.to_modify')}</h2>
                <p className="title-sentence">{t('modification.text')}:</p>
            </div>

            <SearchBox interno={false} modification={true} withHeader={false} pmskey={pmskey}/>
        </div>
    )
}