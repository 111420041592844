import React, {Component} from "react";
import Header from "../Header/Header";
import {SearchResult} from "../SerachResult/SearchResult";
import Footer from "../Footer/Footer";
import HotelBannerAdvantages from "../HotelBannerAdvantages/HotelBannerAdvantages";
import EngineResult from "../EngineResult";
import AgencyBanner from "../AgencyBanner";
import {connect} from "react-redux";
import {WarningMessage} from "../Dashboard/CallCenterUtils";
import queryString from "query-string";
import {setModifyRoom} from "../../actions/reservations";
import {Maintenance} from "../Maintenance/Maintenance";


class BookingEngine extends Component {

    constructor(props) {
        super(props);

        if (typeof props.match.params.hotelname !== 'undefined') {
            this.state = ({
                hotel: props.match.params.hotelname,
                search_query: props.location.search,
            });
            this.props.handleSelectOnThemesInApp(props.match.params.hotelname);
        } else {
            this.state = ({
                hotel: 'spring',
                search_query: props.location.search,
            });
            this.props.handleSelectOnThemesInApp(this.state.hotel);
        }

        const data = queryString.parse(props.location.search);
        if(data?.changeRoomId) {
            this.props.setModifyRoom(data?.changeRoomId);
        }
    }

    getHotelMaintenance(hotel) {
        if (hotel === "bitacora" && (process.env.REACT_APP_BITACORA_MAINTENANCE === 'true')) {
            return true;
        } else if (hotel === "arona" && (process.env.REACT_APP_ARONA_MAINTENANCE === 'true')) {
            return true;
        } else if (hotel === "vulcano" && (process.env.REACT_APP_VULCANO_MAINTENANCE === 'true')) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Header hotel={this.state.hotel}/>
                <SearchResult hotel={this.state.hotel} search_query={this.state.search_query}/>

                {this.props.isCallCenterActive && <WarningMessage/>}

                {this.props.isAgency && !this.props.spring40active && <AgencyBanner/>}

                {this.getHotelMaintenance(this.state.hotel) ?
                    <Maintenance/>
                    :
                    <>
                        {/*<HotelBannerAdvantages hotel={this.state.hotel}/>*/}
                        {/*<EngineResult hotel={this.state.hotel} search_query={this.state.search_query}/>*/}
                        {!this.props.isModifying && <HotelBannerAdvantages hotel={this.state.hotel}/>}
                        <EngineResult hotel={this.state.hotel} search_query={this.state.search_query}/>
                    </>
                }

                {/*{!this.props.isModifying && <HotelBannerAdvantages hotel={this.state.hotel}/>}*/}
                {/*<EngineResult hotel={this.state.hotel} search_query={this.state.search_query}/>*/}

                <Footer hotel={this.state.hotel}/>
            </React.Fragment>
        );

    }
}

const mapStateToProps = state => ({
    isAgency: state.auth.isAgency,
    isCallCenterActive: state.callcenter.callCenterActive,
    spring40active: state.search.payload?.spring40,
    isModifying: state.reservations.modification?.isModifying
});

export default connect(
    mapStateToProps, {setModifyRoom}
)(BookingEngine);