/*
Renderiza:
-Nombre Habitación
-Texto descripción
-BoardTypesAPI
-Slider de habitación
-Componente de servicios incluídos
 */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import BoardTypeItem from "./BoardTypeItem";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Paper} from "@material-ui/core";
import RoomGallery from "./RoomGallery";


const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['A500'],
    overflow: 'hidden',
    marginTop: 10,
    padding: 10,
    paddingBottom: 10,
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  grid: {
    width: 1200
  }
});

class BoardTypes extends Component {

  state = {
        boardTypesData: this.props.boardtypesdata,
        room: this.props.room,
        description: this.props.description,
        slider1: this.props.slider1,
        slider2: this.props.slider2
   };

  getBoardTypes() {
      return Object.keys(this.state.boardTypesData).map( (boardtype) => {
          return <BoardTypeItem
              key = {boardtype}
              boardtype={boardtype}
              pricewithoutoffer={
                  this.state.boardTypesData[boardtype]["PriceWithoutOffer"]}
              pricewithoffer={
                  this.state.boardTypesData[boardtype]["PriceWithOffer"]}
              Availability={
                  this.state.boardTypesData[boardtype]["Availability"]
              }/>;
      })
  }

  render() {
      const { classes } = this.props;
      return (
          <React.Fragment>
            <CssBaseline />
              <div className={classes.root}>
                  <Paper className={classes.paper}>
                              <Typography color="secondary" variant="h4" gutterBottom>
                                    {this.state.room}
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                  {typeof this.state.description !== 'undefined' ?
                                      this.state.description.split('\\n').map((item, i) => {
                                          return <span key={i}>{item}<br/><br/></span>
                                      }) : ""
                                  }
                              </Typography>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <RoomGallery sliderimages={this.state.slider1} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <RoomGallery sliderimages={this.state.slider2} />
                                </Grid>
                              </Grid>
                              <Paper className={classes.paper}>
                                  (Componente de Extras)
                              </Paper>
                               { this.getBoardTypes() }
                  </Paper>
              </div>
      </React.Fragment>
      )
  }

}

export default withStyles(styles)(BoardTypes);