export const GET_HOTEL_PRICES= "GET_HOTEL_PRICES";
export const SEARCH = 'SEARCH';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';

export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_SUCCESS_AGENCY = "LOGIN_SUCCESS_AGENCY";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_MODIFY_INFO = "USER_MODIFY_INFO";
export const RECOVER_PASS = "RECOVER_PASS";
export const CONFIRM_PASS = "CONFIRM_PASS";

export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";

export const GET_RESERVATIONS = "GET_RESERVATIONS";
export const GET_RESERVATION_DETAIL = "GET_RESERVATION_DETAIL";
export const GET_RESERVATION_ROOM = "GET_RESERVATION_ROOM";
export const CANCEL_ROOM = "CANCEL_ROOM";

export const AGENCY_COMMISSIONVIEW_UPDATE= "AGENCY_COMMISSIONVIEW_UPDATE";

export const CONFIRM_BUTTON= "CONFIRM_BUTTON";

export const CALLCENTER_LOGIN = "CALLCENTER_LOGIN";
export const CALLCENTER_LOGOUT = "CALLCENTER_LOGOUT";
export const CALLCENTER_USER_LOADED = "CALLCENTER_USER_LOADED";

export const GET_AGENTS = "GET_AGENTS";
export const AGENT_REGISTER = "AGENT_REGISTER";
export const AGENT_REGISTER_ERROR = "AGENT_REGISTER_ERROR";
export const AGENT_REGISTER_CLEAR = "AGENT_REGISTER_CLEAR";
export const AGENT_SPRING40 = "AGENT_SPRING40";

export const GET_AVAILABILITY = "GET_AVAILABILITY";
export const NO_AVAILABILITY = "NO_AVAILABILITY";

export const SET_ROOM_MODIFICATION = "SET_ROOM_MODIFICATION";
export const UNSET_ROOM_MODIFICATION = "UNSET_ROOM_MODIFICATION";

export const ALERTS_CREATE_DASHBOARD = "ALERTS_CREATE_DASHBOARD";
export const ALERTS_DELETE_DASHBOARD = "ALERTS_DELETE_DASHBOARD";

export const TRANSFER_REQUEST = "TRANSFER_REQUEST";
export const GET_AIRLINES = "GET_AIRLINES";