import {
    GET_RESERVATIONS,
    GET_RESERVATION_DETAIL,
    GET_RESERVATION_ROOM,
    CANCEL_ROOM,
    GET_AVAILABILITY,
    NO_AVAILABILITY,
    SET_ROOM_MODIFICATION,
    UNSET_ROOM_MODIFICATION,
    TRANSFER_REQUEST
} from '../actions/types.js';

const initialState = {
    reservations: [],
    isLoaded: false,
    checkAvailability: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_RESERVATIONS:
            return {
                ...state,
                reservations: action.payload,
                isLoaded: true
            };
        case GET_RESERVATION_DETAIL:
            return {
                ...state,
                detail: action.payload[0],
                isLoaded: true
            };
        case GET_RESERVATION_ROOM:
            return {
                ...state,
                room: action.payload,
                isLoaded: true
            };
        case CANCEL_ROOM:
            return {
                ...state,
                response: action.payload,
            };
        case TRANSFER_REQUEST:
            return {
                ...state,
                transfer: action.payload?.transfer,
            };
        case SET_ROOM_MODIFICATION:
            return {
                ...state,
                modification: {
                    isModifying: true,
                    idRoomToChange: action.payload,
                },
            };
        case UNSET_ROOM_MODIFICATION:
            return {
                ...state,
                modification: {
                    isModifying: false,
                },
            };
        case GET_AVAILABILITY:
            return {
                ...state,
                checkAvailability: action.payload?.checkAvailability,
            };
        case NO_AVAILABILITY:
        default:
            return state;
    }
}