import React, {useState} from 'react';
import ItemsCarousel from "react-items-carousel";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export function Carousel(props) {
    const {t} = useTranslation('common');
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    let numberOfCards = 4;
    let left = <button>{'<'}</button>;
    let right = <button>{'>'}</button>;

    const products = useSelector(state => state.shelf.products);

    if (props.isMobile) {
        numberOfCards = 2;
        left = <></>;
        right = <></>;
    }

    const listItems = products.filter(
        p => p.room === props.product.id.split("-")[1]).map(
        filtered => filtered.images_carousel.map(photo =>
            <div className="room-up-element">
                <img src={photo.image} alt=""/>
                <p className="title">{t([`carousel.${photo?.description}`, ''])}</p>
            </div>
        )
    );

    return (
        <div style={{padding: `0 20px`}}>
            <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={numberOfCards}
                slidesToScroll={2}
                gutter={20}
                activePosition={'center'}
                leftChevron={left}
                rightChevron={right}
                outsideChevron
                showSlither={true}
                chevronWidth={20}
            >
                {listItems}
            </ItemsCarousel>
        </div>
    )
}