import React, {Component, useState} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import Collapse from '@material-ui/core/Collapse';
import {isBrowser} from "react-device-detect";

import LoginPage from "../../../Login/LoginPage";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import Spinner from "../../../Spinner";

import {getRoomReservation} from "../../../../actions/reservations";
import Popup from "reactjs-popup";
import CancelationForm from "../../Cancelation/CancelationForm";
import {MainMessage} from "../../CallCenterUtils";

import ModifyPopUp from "./ModifyPopUp";
import TaxiPopUp from "./TaxiPopUp";
import ModifyAll from "./ModifyAll";
import TransferPage from "../../Transfer/TransferPage";
import moment from "moment";

const IP_DJANGO = process.env.REACT_APP_DJANGO_IP;
const PORT_DJANGO = process.env.REACT_APP_DJANGO_PORT;
const HTTP_PROTOCOL = process.env.REACT_APP_HTTP_PROTOCOL;


export class BookingRoom extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
    };

    state = {
        isOpen: false
    };

    componentDidMount() {
        this.props.getRoomReservation(this.props.match.params.pmskey);
        if (isBrowser) {
            this.setState({isOpen: true});
        }
    }

    openCollapse = () => {
        this.setState({isOpen: !(this.state.isOpen)});
    };

    render() {
        const {t} = this.props;
        const {isAuthenticated} = this.props.auth;
        const room = this.props.room;
        const URL = HTTP_PROTOCOL + "://" + IP_DJANGO + ":" + PORT_DJANGO;
        const roomImage = URL + room?.roomimage;
        const reservationURL = "/myreservations/detail/" + room?.reservation;
        const pmskey = this.props.match.params.pmskey;

        const percentage_days = (days = room?.days_remain) => {
            if (days > 30) {
                return 15;
            } else {
                return 100 - ((days * 100) / 30)
            }
        };

        let bgcolor = "#84B440";

        const percentage_color = (percentage) => {
            if (percentage === 100 || room?.status_room === 'canceled') {
                bgcolor = "#CC4531";
                return 'booking-view-progress color-red'
            } else if (percentage >= 75) {
                bgcolor = "#FFC857";
                return 'booking-view-progress color-yellow'
            } else {
                return 'booking-view-progress color-green'
            }
        };

        const css_reservation_status = (status = room?.status_room) => {
            if (status === 'confirmed') {
                return 'booking-view-header confirmed'
            } else if (status === 'canceled') {
                return 'booking-view-header cancelled'
            } else if (status === 'pending') {
                return 'booking-view-header'
            } else if (status === 'completed') {
                return 'booking-view-header'
            } else {
                return 'booking-view-header'
            }
        };

        const isCancelled = () => {
            if (room?.status_room === 'canceled') {
                return true;
            } else {
                return false;
            }
        };

        const peopleText = () => {
            if (room?.childsnum > 0) {
                return room?.adults + ' ' + t('search.adults') + ', ' + room?.childsnum + ' ' + t('search.children')
            } else {
                return room?.adults + ' ' + t('search.adults')
            }
        };

        return (
            <>
                {this.props.isLoaded ? null : <Spinner/>}
                {isAuthenticated ?
                    <>
                        <Header hotel="spring"/>

                        {this.props.callcenter.callCenterActive && <MainMessage/>}

                        <main className="container apriv-page">
                            <header className="apriv-header small-margin">
                                <a href="/myreservations" className="back-link">
                                    <span>{t('msg.back_reservations')}</span>
                                </a>
                            </header>

                            {this.props.dashboardAlert &&
                                <div className={"system-msg " + this.props.dashboardAlert.msgType}>
                                    <div className="msg-box">
                                        <div classNamemoment="text-content">
                                            <p><strong>{this.props.dashboardAlert.msg}</strong></p>
                                        </div>
                                    </div>
                                    {this.props.getRoomReservation(this.props.match.params.pmskey)}
                                </div>
                            }


                            <section className="booking-view-wrapper">
                                <header className={css_reservation_status()}>
                                    <p className="label">
                                        <span>
                                            {t([`reservation_status.${room?.status_room}`, 'reservation_status.pending'])}
                                        </span>
                                    </p>
                                    <h1 className="title">{t('reservation.reservation_id_short')}: {room?.pms_resid_key}</h1>
                                    <p className="room-info">{t([`roomtypes.${room?.translation_code}`, 'roomtypes.unspecific']) + " - Hotel " + room?.hotel}</p>
                                    <p>
                                        <a href={reservationURL} className="booking-id"><span
                                            className="site-color">{t('msg.reservation_data')}</span></a>
                                    </p>

                                </header>

                                <div className="mobile-price-info">
                                    <div className="booking-view-price">
                                        <p className="title">{t('cart.price')}</p>
                                        {(room?.client_price === room?.booking_price) || (this.props.isAgency) ?
                                            <p className="price"><strong className="final"
                                                                         title="final">{room?.client_price}€</strong>
                                            </p>
                                            :
                                            <p className="price"><strong className="final"
                                                                         title="final"><strike>{room?.booking_price}€</strike> {room?.client_price}€</strong>
                                            </p>
                                        }
                                    </div>
                                    {/*<a href="#" className="booking-view-match-price">*/}
                                    {/*    <span>Iguala la tarifa y ahorra 325,12€</span>*/}
                                    {/*</a>*/}
                                </div>

                                <div className="booking-view-contain">
                                    <div className="booking-view-info">
                                        <article className="booking-view-room">
                                            <div className="booking-rom-img"
                                                 style={{backgroundImage: `url("${roomImage}")`}}>
                                                <img
                                                    src={roomImage}/>
                                            </div>

                                            {process.env.REACT_APP_TAXI_TRANSFER_BANNER === 'true' &&
                                                <div className="taxi-banner">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M20.0469 11.4391L19.1875 8.125C18.8125 6.75 17.5625 5.75 16.1875 5.75H15.75V4.5C15.75 3.80977 15.1902 3.25 14.5 3.25H9.5C8.80977 3.25 8.25 3.80977 8.25 4.5V5.75H7.8125C6.4375 5.75 5.1875 6.75 4.8125 8.125L3.95312 11.4391C2.83633 11.6891 2 12.6824 2 13.875V15.75C2 16.673 2.50586 17.4703 3.25 17.9031V19.5C3.25 20.1902 3.80977 20.75 4.5 20.75H5.75C6.44023 20.75 7 20.1902 7 19.5V18.25H17V19.5C17 20.1902 17.5598 20.75 18.25 20.75H19.5C20.1902 20.75 20.75 20.1902 20.75 19.5V17.9031C21.4941 17.4699 22 16.6727 22 15.75V13.875C22 12.6824 21.1637 11.6891 20.0469 11.4391ZM5.75 15.75C5.05977 15.75 4.5 15.1902 4.5 14.5C4.5 13.8098 5.05977 13.25 5.75 13.25C6.44023 13.25 7 13.8098 7 14.5C7 15.1902 6.44023 15.75 5.75 15.75ZM6.55273 11.375L7.22461 8.78281C7.31172 8.46406 7.59922 8.25 7.81289 8.25H16.1879C16.4016 8.25 16.6891 8.46406 16.768 8.75234L17.4473 11.375H6.55273V11.375ZM18.25 15.75C17.5598 15.75 17 15.1902 17 14.5C17 13.8098 17.5598 13.25 18.25 13.25C18.9402 13.25 19.5 13.8098 19.5 14.5C19.5 15.1902 18.9402 15.75 18.25 15.75Z"
                                                            fill="currentColor"></path>
                                                    </svg>
                                                    <p>Traslados en destino</p>
                                                    {!room?.transfers ?
                                                        <Popup modal class="big-popup"
                                                               trigger={<a>{t('transfer.to_request')}</a>}
                                                               position="right center">
                                                            <TransferPage pmskey={pmskey}/>
                                                        </Popup>
                                                        :
                                                        <>
                                                            <p className="taxi-info">
                                                                <span>{moment(room?.transfers?.to_hotel.flight_datetime).format('L')} a las {moment(room?.transfers?.to_hotel.flight_datetime).format('H:mm')} a nombre de {room?.userdata.first_name}</span>
                                                                <span>{moment(room?.transfers?.to_airport.flight_datetime).format('L')} a las {moment(room?.transfers?.to_airport.flight_datetime).format('H:mm')} a nombre de {room?.userdata.first_name}</span>
                                                            </p>
                                                            <div>
                                                                <Popup modal id="big-popup"
                                                                       trigger={<a>{t('transfer.to_modify')}</a>}
                                                                       position="right center">
                                                                    <TransferPage pmskey={pmskey}/>
                                                                </Popup>
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            }
                                            {!room?.transfers && room?.nights >= 7 &&
                                                <div className="taxi-alert">
                                                    <svg width="8" height="20" viewBox="0 0 8 20" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M7.125 16.875C7.125 18.5981 5.72312 20 4 20C2.27688 20 0.875 18.5981 0.875 16.875C0.875 15.1519 2.27688 13.75 4 13.75C5.72312 13.75 7.125 15.1519 7.125 16.875ZM1.23672 0.984336L1.76797 11.6093C1.79293 12.1083 2.20473 12.5 2.7043 12.5H5.2957C5.79527 12.5 6.20707 12.1083 6.23203 11.6093L6.76328 0.984336C6.79004 0.448828 6.36313 0 5.82695 0H2.17305C1.63687 0 1.20996 0.448828 1.23672 0.984336Z"
                                                            fill="currentColor"/>
                                                    </svg>
                                                    <p>Todavía no has reservado tu taxi gratis</p>
                                                </div>
                                            }

                                            <div class="booking-rom-info">
                                                <h2 class="h3 title">{t('msg.reservation_data')}</h2>
                                                <div class="form-style only-view">
                                                    <div class="inputs-group">
                                                        <div class="column-inputs">
                                                            <div class="input-wrapper">
                                                                <label for="room-type">{t('spring.room')}:</label>
                                                                <input type="text" id="room-type" name="room-type"
                                                                       value={room?.roomtype} disabled/>
                                                            </div>
                                                            <div class="input-wrapper">
                                                                <label for="hotel-name">Hotel</label>
                                                                <input type="text" id="hotel-name" name="hotel-name"
                                                                       value={room?.hotel} disabled/>
                                                            </div>
                                                        </div>
                                                        <div class="column-inputs">
                                                            <div class="input-wrapper">
                                                                <label
                                                                    for="entry-data">{t('words.arrival_date')}:</label>
                                                                <input type="text" id="entry-data" name="entry-data"
                                                                       value={room?.arrival?.split("T")[0] + " -> 12:00 " + t('words.to') + " 00:00"}
                                                                       disabled/>
                                                            </div>
                                                            <div class="input-wrapper">
                                                                <label
                                                                    for="departure-data">{t('words.departure_date')}:</label>
                                                                <input type="text" id="departure-data"
                                                                       name="departure-data"
                                                                       value={room?.departure?.split("T")[0] + " -> 12:00 " + t('words.to') + " 00:00"}
                                                                       disabled/>
                                                            </div>
                                                        </div>
                                                        <div class="column-inputs">
                                                            <div class="input-wrapper">
                                                                <label
                                                                    for="room-pension">{t('words.boardtypes')}:</label>
                                                                <input type="text" id="room-pension" name="room-pension"
                                                                       value={room?.boardtype} disabled/>
                                                            </div>
                                                            <div class="input-wrapper">
                                                                <label for="room-extras">Extras:</label>
                                                                <input type="text" id="room-extras" name="room-extras"
                                                                       value="Ninguno" disabled/>
                                                            </div>
                                                        </div>
                                                        <div class="column-inputs">
                                                            <div class="input-wrapper">
                                                                <label for="discount">{t('search.promotion')}:</label>
                                                                <input type="text" id="discount" name="discount"
                                                                       value={room?.promotion} disabled/>
                                                            </div>
                                                            <div class="input-wrapper">
                                                                <label
                                                                    for="room-occupancy">{t('person.adults') + " & " + t('person.children')}:</label>
                                                                <input type="text" id="room-occupancy"
                                                                       name="room-occupancy"
                                                                       value={peopleText()}
                                                                       disabled/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>

                                        <div className="booking-view-data">
                                            {/*<h2 className="h3 title">Titular de la reserva:</h2>*/}
                                            <div className="form-style only-view">
                                                <div className="inputs-group">
                                                    <div className="column-inputs">
                                                        <div className="input-wrapper">
                                                            <label htmlFor="email">{t('words.email')}</label>
                                                            <input type="email" id="email" name="email"
                                                                   value={room?.userdata?.email} disabled/>
                                                        </div>
                                                        <div className="input-wrapper">
                                                            <label
                                                                htmlFor="phone">{t('words.mobile_phone')}</label>
                                                            <input type="text" id="phone" name="phone"
                                                                   value={"+" + room?.userdata?.phone} disabled/>
                                                        </div>
                                                    </div>
                                                    <div className="column-inputs">
                                                        <div className="input-wrapper">
                                                            <label
                                                                htmlFor="name">{t('words.first_name')}</label>
                                                            <input type="text" id="name" name="name"
                                                                   value={room?.userdata?.first_name}
                                                                   disabled/>
                                                        </div>
                                                        <div className="input-wrapper">
                                                            <label
                                                                htmlFor="surname">{t('words.last_name')}</label>
                                                            <input type="text" id="surname" name="surname"
                                                                   value={room?.userdata?.last_name} disabled/>
                                                        </div>
                                                    </div>
                                                    <div className="column-inputs">
                                                        <div className="input-wrapper">
                                                            <label
                                                                htmlFor="country">{t('words.country')}</label>
                                                            <input type="text" id="country" name="country"
                                                                   value={room?.userdata?.country}
                                                                   disabled/>
                                                        </div>
                                                        <div className="input-wrapper">
                                                            <label
                                                                htmlFor="province">{t('words.region')}</label>
                                                            <input type="text" id="province" name="province"
                                                                   value={room?.userdata?.region}
                                                                   disabled/>
                                                        </div>
                                                    </div>
                                                    <div className="column-inputs">
                                                        <div className="input-wrapper">
                                                            <label
                                                                htmlFor="booking_date">{t('msg.reservation_date')}</label>
                                                            <input type="date" id="booking_date"
                                                                   name="booking_date"
                                                                   value={room?.reserved_datetime?.split("T")[0]}
                                                                   disabled/>
                                                        </div>

                                                        <div className="input-wrapper">
                                                            <label
                                                                htmlFor="booking_date">{t('msg.reservation_date_update')}</label>
                                                            <input type="date" id="booking_date"
                                                                   name="booking_date"
                                                                   value={room?.reserved_updated?.split("T")[0]}
                                                                   disabled/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <aside className="booking-view-aside-wrapper">
                                        <div className="booking-view-aside">
                                            <header className="booking-view-aside-header"
                                                    onClick={this.openCollapse}>
                                                <h3 className="title primary-title-font">{t('msg.reservation_manage')}</h3>
                                            </header>

                                            <Collapse in={this.state.isOpen} timeout="auto" unmountOnExit>

                                                <div className="booking-view-aside-content">
                                                    <div className={percentage_color(percentage_days())}>
                                                        <p>{room?.days_remain} {t('msg.reservation_remain_days')}</p>
                                                        <p className="progress-var">
                                                            <ProgressBar
                                                                completed={percentage_days()}
                                                                bgcolor={bgcolor}
                                                                isLabelVisible={false}
                                                                baseBgColor="#CCD3DD"
                                                                height="8px"
                                                            />
                                                        </p>
                                                    </div>
                                                    {/*FIN DE LA BARRA DE PROGRESO*/}

                                                    <ul className="booking-edit-options">
                                                        {isCancelled() || room?.days_remain === 0 ? null :
                                                            <>
                                                                {!this.props.isAgency &&
                                                                    <Popup modal trigger={
                                                                        <li>
                                                                            <a className="btn btn-primary">{t('modification.to_modify')}</a>
                                                                        </li>
                                                                    } position="right center">
                                                                        {ModifyAll(pmskey, t)}
                                                                    </Popup>
                                                                }
                                                                <li>
                                                                    <Popup modal
                                                                           trigger={<a>{t('msg.room_cancelation')}</a>}
                                                                           position="right center"
                                                                    >
                                                                        <CancelationForm pmskey={pmskey}/>
                                                                    </Popup>
                                                                </li>
                                                                <li><a
                                                                    href="https://springhotels.oceanbooking.com/">{t('flights.search')}</a>
                                                                </li>
                                                            </>
                                                        }

                                                        {/*<li><a>Descargar reserva en PDF</a></li>*/}

                                                    </ul>
                                                </div>

                                            </Collapse>

                                            {/*<footer className="booking-view-aside-footer show-mobile">*/}
                                            <footer className="booking-view-aside-footer">
                                                {this.props.isAgency && !room?.is_alternative_rate &&
                                                    <div className="booking-view-price">
                                                        <p className="title">{t('msg.pvp')}</p>
                                                        <p className="price"><strong className="final"
                                                                                     title="final">{(room?.client_price == room?.booking_price ? (Number(room?.booking_price) + Number(((room?.booking_price * this.props.commission) / 100).toFixed(2))).toFixed(2) : room?.booking_price)}€</strong>
                                                        </p>
                                                        <p className="title">{t('words.commission')} ({Math.round(this.props.commission)}%)</p>
                                                        <p className="price"><strong className="final"
                                                                                     title="final">{((room?.booking_price * this.props.commission) / 100).toFixed(2)}€</strong>
                                                        </p>
                                                    </div>
                                                }

                                                <div className="booking-view-price">
                                                    {this.props.isAgency ?
                                                        <>
                                                            <p className="title">{t('msg.pvc')}</p>
                                                            <p className="price"><strong className="final"
                                                                                         title="final">{room?.client_price}€</strong>
                                                            </p>
                                                        </>
                                                        :
                                                        <>
                                                            <p className="title">{t('cart.price')}</p>
                                                            <p className="price"><strong className="final"
                                                                                         title="final"><strike>{room?.booking_price}€</strike> {room?.client_price}€</strong>
                                                            </p>
                                                        </>
                                                    }
                                                    <a href={reservationURL} className="booking-id"><span
                                                        className="site-color">{t('msg.reservation_data')}</span></a>
                                                </div>

                                                {/*Iguala la tarifa*/}
                                                {/*<a href="#match_rate" className="booking-view-match-price">*/}
                                                {/*    <span>Iguala la tarifa y <strong>ahorra 325,12€</strong></span>*/}
                                                {/*</a>*/}

                                            </footer>

                                        </div>
                                    </aside>
                                </div>
                            </section>
                        </main>

                        <Footer hotel="spring"/>
                    </>
                    :
                    <LoginPage/>
                }
            </>

        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    isAgency: state.auth.isAgency,
    room: state.reservations.room,
    isLoaded: state.reservations.isLoaded,
    callcenter: state.callcenter,
    dashboardAlert: state.alerts?.dashboard,
    commission: state.auth.agency?.descuento
});

export default connect(
    mapStateToProps, {getRoomReservation}
)(withRouter(withTranslation('common')(BookingRoom)));