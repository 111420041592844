import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";


class CartProduct extends Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        removeProduct: PropTypes.func.isRequired,
        changeProductQuantity: PropTypes.func.isRequired,
        indexHab: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            product: this.props.product,
            indexHab: this.props.indexHab,
            isMouseOver: false
        };
    };

    handleMouseOver = () => {
        this.setState({isMouseOver: true});
    };

    handleMouseOut = () => {
        this.setState({isMouseOver: false});
    };

    handleOnIncrease = () => {
        const {changeProductQuantity} = this.props;
        const {product} = this.state;
        product.quantity = product.quantity + 1;
        changeProductQuantity(product);
    };

    handleOnDecrease = () => {
        const {changeProductQuantity} = this.props;
        const {product} = this.state;
        product.quantity = product.quantity - 1;
        changeProductQuantity(product);
    };

    childs_ages = (t, reservation) => {
        if(parseInt(reservation?.room_num_childs) === 1){
            return "("+ reservation?.room_num_childs_0 + " " + t('search.years') + ")"
        }
        else if(parseInt(reservation?.room_num_childs) === 2){
            return "("+ reservation?.room_num_childs_0 + ", " + reservation?.room_num_childs_1 + " " + t('search.years') + ")"
        }
        else if(parseInt(reservation?.room_num_childs) === 3){
            return "("+ reservation?.room_num_childs_0 + ", " + reservation?.room_num_childs_1 + ", " + reservation?.room_num_childs_2 + " " + t('search.years') + ")"
        }
    };

    render() {
        const {removeProduct} = this.props;
        const {product} = this.state;

        const classes = ['cart-room'];

        const {t} = this.props;

        if (!!this.state.isMouseOver) {
            classes.push('shelf-item--mouseover');
        }

        return (
            <div className={classes.join(' ')}>
                <div className="cart-room-header">
                    <h5>{t('spring.room')} {this.state.indexHab}</h5>
                    <p className="price">
                        {!this.props.isAgency &&
                        <span className="old" title="sin descuento">
                              {product.pricewithoutoffer}{product.currencyFormat}
                            </span>
                        }
                        <strong className="final" title="final">
                            {product.pricewithoffer.toFixed(2)}{product.currencyFormat}
                        </strong>
                    </p>
                    {this.props.cartEdit ? null :
                        <a onClick={() => removeProduct(product)} className="remove-room close-icon"
                           title={t('cart.delete')}>
                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M14.3182 2.66666H12.9545H10.9091V0.666656C10.9091 0.298656 10.6043 0 10.2273 0H4.77273C4.39569 0 4.09092 0.298656 4.09092 0.666656V2.66666H2.04546H0.681809C0.304774 2.66666 0 2.96531 0 3.33331C0 3.70131 0.304742 4 0.681809 4H1.36362V14C1.36362 15.1027 2.28136 16 3.40908 16H11.5909C12.7186 16 13.6364 15.1027 13.6364 14V4H14.3182C14.6952 4 15 3.70134 15 3.33334C15 2.96534 14.6953 2.66666 14.3182 2.66666ZM5.45454 1.33334H9.54546V2.66669H5.45454V1.33334ZM11.5909 14.6667C11.9673 14.6667 12.2727 14.3673 12.2727 14H12.2728V4H10.2273H4.77273H2.72727V14C2.72727 14.3673 3.03271 14.6667 3.40908 14.6667H11.5909Z"
                                      fill="currentColor"/>
                                <path
                                    d="M5.5 6C5.2235 6 5 6.28508 5 6.63636V12.3636C5 12.7149 5.2235 13 5.5 13C5.7765 13 6 12.7149 6 12.3636V6.63636C6 6.28508 5.7765 6 5.5 6Z"
                                    fill="currentColor"/>
                                <path
                                    d="M9.5 6C9.2235 6 9 6.28508 9 6.63636V12.3636C9 12.7149 9.2235 13 9.5 13C9.7765 13 10 12.7149 10 12.3636V6.63636C10 6.28508 9.7765 6 9.5 6Z"
                                    fill="currentColor"/>
                            </svg>
                        </a>}
                </div>
                <div className="cart-room-info">

                    <p>
                        {/*<strong>{product.roomText}</strong> - {product.reservation.hotel.charAt(0).toUpperCase() + product.reservation.hotel.slice(1)}*/}
                        <strong>{t('roomtypes.'+ product.translation_code)}</strong> - {product.reservation.hotel.charAt(0).toUpperCase() + product.reservation.hotel.slice(1)}
                    </p>
                    <p>{t('boardtypes.' + product.boardtype + '.title').endsWith('title') ?
                        product.boardtype
                        :
                        t('boardtypes.' + product.boardtype + '.title')}
                    </p>
                    <p><strong>{product.reservation.arrival}</strong> {t('words.to')} <strong>{product.reservation.departure}</strong></p>
                    <p>{t('person.adults').charAt(0).toUpperCase() + t('person.adults').slice(1)}: {product.reservation.adults}</p>
                    {product.reservation.room_num_childs !== "0" ?
                        <p>{t('person.children').charAt(0).toUpperCase() + t('person.children').slice(1)}: {product.reservation.room_num_childs} {this.childs_ages(t, product.reservation)}</p>
                        // <p>{t('person.children').charAt(0).toUpperCase() + t('person.children').slice(1)}: {product.reservation.room_num_childs}</p>
                        : null
                    }
                    {product.reservation.resident !== "false" ?
                        <p>{t('words.resident')}</p>
                        : null
                    }
                    {product.springclub === true ?
                        <p><strong>Spring Club</strong></p> : null
                    }
                    {product.alternative_rate === true ?
                        <p><strong>Spring40</strong></p>
                        : null
                    }
                    {product.christmas_offer === true ?
                        <p><strong>{t('cart.christmas_offer')}</strong></p> : null
                    }

                    <ul className="extra-list">
                        <li>
                            {/*
                            {this.props.cartEdit ? null : <a href="#" className="remove-extra close-icon" title="Quitar"></a> }
                            <span>Limpieza solo días alternos</span>
                            <span className="price">300€</span>
                            */}
                        </li>
                    </ul>
                    {/*{this.props.cartEdit ? null :*/}
                    {/*    <a href="#" className="edit-room">Editar</a>*/}
                    {/*}*/}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isAgency: state.auth.isAgency
});

export default connect(mapStateToProps)(withTranslation('common')(CartProduct));
