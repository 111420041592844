import {FETCH_PRODUCTS, FETCH_PRODUCTS_CLUB, UPDATE_PRODUCTS} from './actionTypes';

const initialState = {
    products: [],
    productsClub: [],
    isDone: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_PRODUCTS:
            return {
                ...state,
                products: action.payload,
            };
        case FETCH_PRODUCTS_CLUB:
            return {
                ...state,
                productsClub: action.payload,
            };
        case UPDATE_PRODUCTS:
            return {
                ...state,
                popupActive:true,
                isDone: true
            };
        default:
            return state;
    }
}
