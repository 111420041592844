import React, {Component} from "react";
import {withTranslation} from "react-i18next";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Login from "./Login";

export class LoginPage extends Component {

    render() {
        const {t} = this.props;
        const {history} = this.props;

        return (
            <React.Fragment>
                <Header hotel="spring"/>

                <main className="container apriv-page">
                    <section className="login-columns">
                        <Login history={history}/>

                        <article className="login-info-wrapper site-bg-light">
                            <div className="login-info">
                                <h3 className="h5 title">{t('loginpage.title_advantages')}:</h3>
                                <ul className="login-info-list">
                                    <li>
                                        <svg width="22px" height="22px" viewBox="0 0 22 15" version="1.1">
                                            <title>Icono Cama</title>
                                            <g id="Login" stroke="none" stroke-width="1" fill="none"
                                               fill-rule="evenodd">
                                                <g id="Home-Spring-Laptop---1440px---Login-fullpage"
                                                   transform="translate(-941.000000, -285.000000)"
                                                   fill="var(--site-color)" fill-rule="nonzero">
                                                    <g id="Group-14" transform="translate(936.000000, 232.000000)">
                                                        <g id="Group-25" transform="translate(0.000000, 44.000000)">
                                                            <g id="bed" transform="translate(5.000000, 9.000000)">
                                                                <path
                                                                    d="M19.5446029,3.34256619 L8.17270876,3.34256619 C7.61710794,3.34256619 6.68961303,3.55315682 6.68961303,4.98248473 L6.68961303,7.16904277 L21.0276986,7.16904277 L21.0276986,4.98248473 C21.0276986,3.55763747 20.1002037,3.34256619 19.5446029,3.34256619 Z"
                                                                    id="Path"></path>
                                                                <polygon id="Path"
                                                                         points="3.59796334 7.64399185 1.43380855 7.64399185 1.43380855 0 0 0 0 14.8175153 1.43380855 14.8175153 1.43380855 10.9910387 20.5527495 10.9910387 20.5527495 14.8130346 21.986558 14.8130346 21.986558 7.64399185"></polygon>
                                                                <circle id="Oval" cx="3.82199593" cy="5.25580448"
                                                                        r="2.00285132"></circle>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <p><strong>{t('loginpage.advantages_1')}</strong> {t('loginpage.advantages_1_1')}</p>
                                    </li>
                                    <li>
                                        <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
                                            <title>Icono Vuelos</title>
                                            <g id="Login" stroke="none" stroke-width="1" fill="none"
                                               fill-rule="evenodd">
                                                <g id="Home-Spring-Laptop---1440px---Login-fullpage"
                                                   transform="translate(-936.000000, -352.000000)">
                                                    <g id="Group-14" transform="translate(936.000000, 232.000000)">
                                                        <g id="Group-25-Copy"
                                                           transform="translate(0.000000, 120.000000)">
                                                            <rect id="Rectangle" x="0" y="0" width="32"
                                                                  height="32"></rect>
                                                            <path
                                                                d="M26.360396,14.3865347 C26.9116832,14.5409901 27.6673267,14.7524752 27.7552475,15.1968317 C27.8146535,15.5057426 27.5176238,15.800396 27.429703,15.909703 C26.279604,17.0978218 20.6360396,18.2645545 18.6946535,18.7849505 C17.2451485,19.1746535 10.3326733,21.0613861 8.2249505,21.5889109 C7.88857771,21.6847201 7.53077896,21.6739281 7.20079208,21.5580198 C6.76118812,21.3845545 6.55920792,21.0352475 6.27168317,20.6075248 C5.57782178,19.550099 4.84594059,18.5140594 4.18534653,17.44 C4.03265286,17.247136 3.97346009,16.9964372 4.02376238,16.7556436 C4.05239386,16.6654383 4.10302423,16.5837764 4.17108911,16.5180198 C4.47367858,16.2529853 4.91928848,16.2322822 5.24514851,16.4681188 C6.00554455,17.0312871 6.77069307,17.5873267 7.52871287,18.1528713 C7.62793907,18.2335202 7.76155613,18.2577327 7.88277228,18.2170297 C9.77267327,17.7037624 11.6641584,17.1968317 13.5572277,16.6962376 C13.7544554,16.6439604 13.8281188,16.6059406 13.6974257,16.3849505 C12.8087129,14.8855446 11.9342574,13.3790099 11.0550495,11.870099 C10.771593,11.4494857 10.8317576,10.8859441 11.1976238,10.5346535 C11.5998544,10.1794098 12.1851565,10.1237581 12.6471287,10.3968317 C13.1817822,10.7675248 13.7069307,11.1524752 14.2368317,11.5326733 C15.8653465,12.7017822 17.4922772,13.8732673 19.1176238,15.0471287 C19.1622242,15.0802013 19.2131984,15.1036656 19.2673267,15.1160396 C19.3370238,15.1304722 19.4093426,15.1255414 19.4764356,15.1017822 C19.6332673,15.0518812 22.009505,14.3033663 23.4756436,14.1512871 C23.9504294,14.1127133 24.4277976,14.1190782 24.9013861,14.170297 C25.3940412,14.1901536 25.8831595,14.2626451 26.360396,14.3865347 L26.360396,14.3865347 Z"
                                                                id="Path" fill="var(--site-color)"
                                                                fill-rule="nonzero"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <p><strong>{t('loginpage.advantages_2')}</strong> {t('loginpage.advantages_2_2')}.</p>
                                    </li>
                                    <li>
                                        <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
                                            <title>Icono Usuario</title>
                                            <g id="Login" stroke="none" stroke-width="1" fill="none"
                                               fill-rule="evenodd">
                                                <g id="Home-Spring-Laptop---1440px---Login-fullpage"
                                                   transform="translate(-936.000000, -428.000000)">
                                                    <g id="Group-14" transform="translate(936.000000, 232.000000)">
                                                        <g id="Group-2" transform="translate(0.000000, 196.000000)">
                                                            <rect id="Rectangle" x="0" y="0" width="32"
                                                                  height="32"></rect>
                                                            <path
                                                                d="M16.2828283,16.9090909 C20.7610714,16.9090909 24.4218381,20.7316589 24.5657103,25.1919192 C24.5691483,25.62118 24.2345592,25.9743724 23.8106387,25.9986671 L23.7641221,26 L8.80808081,26 C8.37531406,26 8.02200427,25.6598043 8.00098895,25.2322506 L8,25.1919192 C8,20.6174394 11.7083485,16.9090909 16.2828283,16.9090909 Z M16.2828283,6 C18.8489998,6 20.9292929,8.08029308 20.9292929,10.6464646 C20.9292929,13.2126362 18.8489998,15.2929293 16.2828283,15.2929293 C13.7166567,15.2929293 11.6363636,13.2126362 11.6363636,10.6464646 C11.6363636,8.08029308 13.7166567,6 16.2828283,6 Z"
                                                                id="Combined-Shape" fill="var(--site-color)"
                                                                fill-rule="nonzero"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <p><strong>{t('loginpage.advantages_3')}.</strong></p>
                                    </li>
                                    <li>
                                        <svg width="25px" height="25px" viewBox="0 0 25 10" version="1.1">
                                            <title>Icono Taxi</title>
                                            <g id="Login" stroke="none" stroke-width="1" fill="none"
                                               fill-rule="evenodd">
                                                <g id="Home-Spring-Laptop---1440px---Login-fullpage"
                                                   transform="translate(-940.000000, -487.000000)"
                                                   fill="var(--site-color)">
                                                    <g id="Group-14" transform="translate(936.000000, 232.000000)">
                                                        <g id="Group-4" transform="translate(0.000000, 244.000000)">
                                                            <path
                                                                d="M24.3824253,11.2 C24.6985763,11.2 24.9850734,11.3861868 25.1134745,11.6750892 L28.6690301,19.6750892 C28.8484733,20.0788366 28.666639,20.551606 28.2628916,20.7310492 C28.1606044,20.7765102 28.0499155,20.8 27.9379808,20.8 L4.8,20.8 C4.3581722,20.8 4,20.4418278 4,20 C4,19.8880653 4.02348978,19.7773764 4.06895076,19.6750892 L7.62450632,11.6750892 C7.75290739,11.3861868 8.03940458,11.2 8.35555556,11.2 L24.3824253,11.2 Z M12.1849904,13.36 L8.54899042,13.36 L8.54899042,14.224 L9.88099042,14.224 L9.88099042,18.4 L10.8529904,18.4 L10.8529904,14.224 L12.1849904,14.224 L12.1849904,13.36 Z M15.2089904,13.36 L14.5249904,13.36 L12.3649904,18.4 L13.3801904,18.4 L13.8553904,17.284 L15.8785904,17.284 L16.3537904,18.4 L17.3689904,18.4 L15.2089904,13.36 Z M18.8089904,13.36 L17.6929904,13.36 L19.2193904,15.7144 L17.4769904,18.4 L18.5929904,18.4 L19.8169904,16.4848 L21.0409904,18.4 L22.1569904,18.4 L20.4073904,15.7144 L21.9409904,13.36 L20.8249904,13.36 L19.8169904,14.9512 L18.8089904,13.36 Z M23.8129904,13.36 L22.8409904,13.36 L22.8409904,18.4 L23.8129904,18.4 L23.8129904,13.36 Z M14.8633904,14.9296 L15.5041904,16.42 L14.2225904,16.42 L14.8633904,14.9296 Z"
                                                                id="Combined-Shape"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <p><strong>{t('loginpage.advantages_4')}</strong> {t('loginpage.advantages_4_4')}.</p>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </section>
                </main>

                <Footer hotel="spring"/>
            </React.Fragment>
        );
    }
}

export default withTranslation('common')(LoginPage)