import React, {Component} from "react";
import {withTranslation} from "react-i18next";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import CheckoutForm from "./checkoutForm/checkoutForm";
import {WarningMessage} from "../Dashboard/CallCenterUtils";
import {connect} from "react-redux";


class Checkout extends Component {

    constructor(props) {
        super(props);

        if (typeof props.match.params.hotelname !== 'undefined') {
            this.state = ({
                hotel: props.match.params.hotelname,
                search_query: props.location.search,
            });
            this.props.handleSelectOnThemesInApp(props.match.params.hotelname);
        } else {
            this.state = ({
                hotel: 'spring',
                search_query: props.location.search,
            });
            this.props.handleSelectOnThemesInApp(this.state.hotel);
        }
    }


    render() {
        const {t} = this.props;

        return (
            <React.Fragment>
                <Header hotel={this.state.hotel}/>

                {this.props.isCallCenterActive && <WarningMessage/>}

                <section className="checkout container">
                    <header className="checkout-header">
                        <h1>{t('reservation.confirm')}</h1>
                    </header>

                    <CheckoutForm hotel={this.state.hotel}/>
                </section>

                <Footer hotel={this.state.hotel}/>
            </React.Fragment>
        );

    }
}

const mapStateToProps = state => ({
    isCallCenterActive: state.callcenter.callCenterActive
});

export default connect(
    mapStateToProps
)(withTranslation('common')(Checkout));
