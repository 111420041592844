import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

import LogoSpring from "../../static/img/logo-spring-full.svg";
import Konami from "react-konami-code";

export class Footer extends Component {

    changeLanguage(i18n, lng) {
        i18n.changeLanguage(lng);
    };

    render() {
        const {t, i18n} = this.props;

        const hotelUrl = (t, lang) => {
            try {
                if (this.props.hotel === 'arona') {
                    return <a
                        href={"https://www.springhoteles.com/" + lang + "/arona-gran-hotel-tenerife"}>{t('discover.hotel')} {t('spring.arona')}</a>
                }
                if (this.props.hotel === 'bitacora') {
                    return <a
                        href={"https://www.springhoteles.com/" + lang + "/hotel-bitacora-tenerife"}>{t('discover.hotel')} {t('spring.bitacora')}</a>
                }
                if (this.props.hotel === 'vulcano') {
                    return <a
                        href={"https://www.springhoteles.com/" + lang + "/hotel-vulcano-tenerife"}>{t('discover.hotel')} {t('spring.vulcano')}</a>
                }
            } catch (e) {
            }
        };

        return (
            <footer id="motor_footer">
                <div className="pre-footer">
                    <div className="container">
                        <a href="https://www.springhoteles.com/es/spring-hoteles-tenerife" className="group-logo">
                            <img src={LogoSpring} alt="SPRING, Hotel Group"/>
                            <Konami code={[72, 69, 67, 84, 79, 82]}>
                                <br/><br/>
                                <h4 className="nav-title container">{'Made with ❤️ by Héctor Fig.'}</h4>
                            </Konami>
                        </a>

                    </div>
                </div>
                <nav className="footer-nav">
                    <div className="desktop-container">
                        <div className="container group-logo-wrapper">
                            <a href="https://www.springhoteles.com/es/spring-hoteles-tenerife">
                                <img src={LogoSpring} alt="SPRING, Hotel Group"/>
                            </a>
                            <Konami code={[72, 69, 67, 84, 79, 82, 70, 73]}>
                                <br/><br/>
                                <h4 className="nav-title container">{'Made with ❤️ by Héctor Fig.'}</h4>
                            </Konami>
                        </div>

                        <div className="col-nav">
                            <h4 className="nav-title container">{t('spring.hotels')}</h4>
                            <ul>
                                <li className="container">
                                    {hotelUrl(t, i18n.language)}
                                </li>
                                <li className="container">
                                    <a href={"https://www.springhoteles.com/" + i18n.language + "/spring-hoteles-tenerife"}>{t('spring.know')}</a>
                                </li>
                                <li className="container">
                                    <a href={"https://www.springhoteles.com/" + i18n.language + "/atencion-cliente-hotel-tenerife-canarias"}>{t('contact.title')}</a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-nav opened">
                            <h4 className="nav-title container">Legal</h4>
                            <ul styles="display:block;">
                                <li className="container">
                                    <a href={"https://www.springhoteles.com/" + i18n.language + "/declaracion-sobre-cookies"}>{t('contact.conditions')}</a>
                                </li>
                                <li className="container">
                                    <a href={"https://www.springhoteles.com/" + i18n.language + "/declaracion-sobre-cookies"}>{t('contact.privacy')}</a>
                                </li>
                                <li className="container">
                                    <a href={"https://www.springhoteles.com/" + i18n.language + "/declaracion-sobre-cookies"}>{t('contact.cookies')}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                <aside className="sub-footer">
                    <div className="container">
                        <div className="select-lang">
                            <select className="lang-options" value={i18n.language} id="lang" style={{width: 70}}
                                    onChange={(event) => this.changeLanguage(i18n, event.target.value)}>
                                <option value='en' title="English">EN</option>
                                <option value='es' title="Spanish">ES</option>
                                <option value='fr' title="French">FR</option>
                                <option value='de' title="German">DE</option>
                                <option value='nl' title="Dutch">NL</option>
                            </select>
                        </div>
                        <p className="copyrigt">{t('spring.copyright')}</p>
                    </div>
                    <div className="container">
                        <a className="copyrigt" href="https://stats.uptimerobot.com/rB38nFAOgK">Status Page</a>
                    </div>
                </aside>

            </footer>
        );
    }
}

export default withTranslation('common')(Footer);