import axios from "axios";
import {returnErrors} from "./messages";

import {
    USER_LOADED, USER_LOADING, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, REGISTER_SUCCESS, REGISTER_FAIL,
    USER_MODIFY_INFO, LOGIN_SUCCESS_AGENCY, RECOVER_PASS, CONFIRM_PASS, CALLCENTER_LOGIN, CALLCENTER_LOGOUT,
    CALLCENTER_USER_LOADED
} from "./types";
import {loginGTM, logoutGTM} from "../components/GTMComponent/GTMComponent";
import {removeCart} from "../services/cart/actions";
import {cartTotalDelete} from "../services/total/actions";


const IP_DJANGO = process.env.REACT_APP_DJANGO_IP;
const PORT_DJANGO = process.env.REACT_APP_DJANGO_PORT;
const HTTP_PROTOCOL = process.env.REACT_APP_HTTP_PROTOCOL;

export const BASEURL = HTTP_PROTOCOL + '://' + IP_DJANGO + ':' + PORT_DJANGO;


const loginUserTypes = (res, agency, cookies) => (dispatch) => {
    if (agency) {
        dispatch({
            type: LOGIN_SUCCESS_AGENCY,
            payload: res.data
        });
        cookies.set('user_id', res.data.agency.pms_number, {path: '/'});
    } else {
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
        if (res.data.user.rol === "agent") {
            cookies.set('user_id', res.data.user.id_salesforce, {path: '/'})
        } else {
            cookies.set('user_id', res.data.user.no_field, {path: '/'})
        }
    }
    loginGTM();
};

// CHECK TOKEN & LOAD USER
export const loadUser = () => (dispatch, getState) => {
    let url = '/api/auth/user';

    // User Loading
    dispatch({type: USER_LOADING});
    axios
        .get(BASEURL + url, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: USER_LOADED,
                payload: res.data
            });
        });

    // ContactCentre User Loading
    if (getState().callcenter.callCenterActive) {
        url += '?email=' + getState().callcenter.emailCustomer;

        axios
            .get(BASEURL + url, tokenConfig(getState))
            .then(res => {
                dispatch({
                    type: CALLCENTER_USER_LOADED,
                    payload: res.data
                });
            });
    }
};

export const autoLoadUser = (cookies) => (dispatch, getState) => {
    let url = '/api/auth/auto/login';
    const msg = cookies.cookies['sph-auth'];

    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    // Request Body
    const body = JSON.stringify({msg});

    // User Loading
    axios
        .post(BASEURL + url, body, config)
        .then(res => {
            if (res.data.user?.rol === "agent" || res.data.user?.rol === 'user') {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res.data
                });
                if (res.data.user.rol === "agent") {
                    cookies.set('user_id', res.data.user.id_salesforce, {path: '/'})
                } else {
                    cookies.set('user_id', res.data.user.no_field, {path: '/'})
                }
            } else {
                dispatch({
                    type: LOGIN_SUCCESS_AGENCY,
                    payload: res.data
                });
                cookies.set('user_id', res.data.agency.pms_number, {path: '/'});
            }
            loginGTM();
        })
        .catch(err => {
            dispatch({
                type: LOGIN_FAIL
            });
        });
};

// LOGIN USER
export const login = (username, password, agency, cookies) => dispatch => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    // Request Body
    const body = JSON.stringify({username, password, agency});

    let url = BASEURL;
    if (agency)
        url += '/api/auth/agency/login';
    else
        url += '/api/auth/user/login';

    dispatch({
        type: USER_LOADING,
    });

    axios
        .post(url, body, config)
        .then(res => {
            if (agency) {
                dispatch({
                    type: LOGIN_SUCCESS_AGENCY,
                    payload: res.data
                });
                cookies.set('user_id', res.data.agency.pms_number, {path: '/'});
                // Borrar carrito al hacer login
                dispatch(removeCart());
                dispatch(cartTotalDelete());
            } else {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res.data
                });
                if (res.data.user.rol === "agent") {
                    cookies.set('user_id', res.data.user.id_salesforce, {path: '/'});
                    // Borrar carrito al hacer login
                    dispatch(removeCart());
                    dispatch(cartTotalDelete());
                } else {
                    cookies.set('user_id', res.data.user.no_field, {path: '/'});
                }
            }
            loginGTM();
        })
        .catch(err => {
            dispatch(returnErrors(err.response?.data, err.response?.status));
            dispatch({
                type: LOGIN_FAIL
            });
        });
};


export const loginCallCenter = (username, password, customer_email, cookies) => dispatch => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    function emailCheck() {
        return axios.post(BASEURL + '/api/auth/user/email', JSON.stringify({"email": customer_email}), config).then(res => res.data['email_result']);
    }

    emailCheck().then(data => {
        if (data === 2) {
            const body = JSON.stringify({username, password});

            axios
                .post(BASEURL + '/api/auth/user/login', body, config)
                .then(res => {
                    dispatch({
                        type: CALLCENTER_LOGIN,
                        payload: customer_email
                    });
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: res.data
                    });
                    cookies.set('user_id', res.data.user.no_field, {path: '/'});
                    loginGTM();
                })
                .catch(err => {
                    dispatch(returnErrors(err.response?.data, err.response?.status));
                    dispatch({
                        type: LOGIN_FAIL
                    });
                });
        } else {
            alert("INVALID CUSTOMER EMAIL");
            dispatch({
                type: LOGIN_FAIL
            });
        }
    });
};

// REGISTER USER
export const register = ({username, password, email}) => dispatch => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    // Request Body
    const body = JSON.stringify({username, email, password});

    axios
        .post(BASEURL + '/api/auth/user/register', body, config)
        .then(res => {
            dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: REGISTER_FAIL
            });
        });
};

// LOGOUT USER
export const logout = (cookie) => (dispatch, getState) => {
    axios
        .post(BASEURL + '/api/auth/logout/', null, tokenConfig(getState))
        .then(res => {
            // Se refresca la pag para poner el precio sin ser spring club
            window.location.reload(false);
            dispatch({
                type: LOGOUT_SUCCESS
            });
            cookie.remove("user_id");
            if (cookie.cookies['sph-auth']) {
                cookie.remove("sph-auth");
            }
            logoutGTM();
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });

    if (getState().callcenter.callCenterActive) {
        dispatch({
            type: CALLCENTER_LOGOUT
        });
    }
};

// Setup config with token - helper function
export const tokenConfig = getState => {
    // Get token from state
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    // If token, add to headers config
    if (token) {
        config.headers["Authorization"] = `Token ${token}`;
    }

    return config;
};

export const tokenAuthConfig = token => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    // If token, add to headers config
    if (token) {
        config.headers["Authorization"] = `Token ${token}`;
    }

    return config;
};

//MODIFY PERSONAL USER DATA
export const modifyPersonalUserData = (form) => (dispatch, getState) => {
    axios
        .post(BASEURL + '/api/auth/user/modify', form, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: USER_MODIFY_INFO,
                payload: res.data
            });
        })
        .catch(err => {
        });
};

//PASSWORD RECOVERY
export const passwordRecovery = (form) => dispatch => {
    axios
        .post(BASEURL + '/api/auth/password_reset', form)
        .then(res => {
            dispatch({
                type: RECOVER_PASS,
                payload: res.data
            });
            alert("Email sent, please check your email.");
            // alert(res.data?.status);
            window.location.href = "/";
        })
        .catch(err => {
        });
};

//PASSWORD CONFIRM
export const passwordConfirm = (form) => dispatch => {
    axios
        .post(BASEURL + '/api/password_reset/confirm/', form)
        .then(res => {
            dispatch({
                type: CONFIRM_PASS,
                payload: res.data
            });
            alert(res.data?.status);
            window.location.href = '/myreservations';
        })
        .catch(err => {
            alert("ERROR: This password is too common.");
        });
};