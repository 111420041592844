import React from "react";
import queryString from "query-string";
import Popup from 'reactjs-popup';
import Toggle from 'react-toggle'
import {useTranslation} from "react-i18next";
import {useSelector, useDispatch} from "react-redux";
import {spring40Update} from "../../actions/agency";

import {SearchBox} from "../SearchBox/SearchBox";

import ImgSearch from "../../static/img/icons/ico-search.svg";


export function SearchResult(props) {

    const data = queryString.parse(props.search_query);
    const hotel = props.hotel;
    const isModifying = useSelector(state => state.reservations.modification?.isModifying);
    const isSpring40 = useSelector(state => state.auth.agency?.is_spring40);
    const spring40 = useSelector(state => state.agency?.spring40active) || data?.spring40;
    const dispatch = useDispatch();

    const {t, i18n} = useTranslation('common');

    const childs_ages = () => {
        if (parseInt(data?.room_num_childs) === 1) {
            return "(" + data?.room_num_childs_0 + " " + t('search.years') + "),"
        } else if (parseInt(data?.room_num_childs) === 2) {
            return "(" + data?.room_num_childs_0 + ", " + data?.room_num_childs_1 + " " + t('search.years') + "),"
        } else if (parseInt(data?.room_num_childs) === 3) {
            return "(" + data?.room_num_childs_0 + ", " + data?.room_num_childs_1 + ", " + data?.room_num_childs_2 + " " + t('search.years') + "),"
        }
    };

    const childs_num = () => {
        if (parseInt(data?.room_num_childs) > 0) {
            return data?.room_num_childs + " " + t('person.children')
        }
    };

    return (
        <>
            {isModifying ?
                <div className="current-search-bar site-bg">
                    <div className="container">
                        <div className="current-search">
                            <h1 className="title">{t('search.your')}</h1>
                            <h3 className="search-txt">
                                {hotel?.charAt(0).toLocaleUpperCase() + hotel?.slice(1)}, {data.adults} {t('person.adults')}, {childs_num()} {childs_ages()} {data.arrival} {t('words.to')} {data.departure}
                                {data.resident === "true" ? ", " + t('words.resident') : null}
                            </h3>
                        </div>
                    </div>
                </div>
                :
                <Popup modal trigger={
                    <div className="current-search-bar site-bg">
                        <div className="container">
                            <div className="current-search">
                                <h1 className="title">{t('search.your')}</h1>
                                <h3 className="search-txt">
                                    {hotel?.charAt(0).toLocaleUpperCase() + hotel?.slice(1)}, {data.adults} {t('person.adults')}, {childs_num()} {childs_ages()} {data.arrival} {t('words.to')} {data.departure}
                                    {data.resident === "true" ? ", " + t('words.resident') : null}
                                </h3>
                            </div>
                            <a title={t('search.modify')} className="search-btn ico-search">
                                <img src={ImgSearch} alt="logo-img"/>
                            </a>
                        </div>
                    </div>
                } position="right center">
                    <SearchBox lang={i18n} modal_mode={true} isSpring40={isSpring40}/>
                </Popup>
            }
        </>
    )
}