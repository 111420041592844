import { combineReducers } from "redux";
import hotelprices from "./hotelprices";
import search from "./search";
import auth from "./auth";
import errors from "./errors";
import messages from "./messages";
import reservations from "./reservations";
import agency from "./agency";
import checkout from "./checkout";
import callcenter from "./callcenter";
import alerts from "./alerts";
import shelfReducer from '../services/shelf/reducer';
import cartReducer from '../services/cart/reducer';
import totalReducer from '../services/total/reducer';
import filtersReducer from '../services/filters/reducer';
import sortReducer from '../services/sort/reducer';


export default  combineReducers({
    hotelprices,
    search,
    auth,
    errors,
    messages,
    reservations,
    agency,
    checkout,
    callcenter,
    alerts,
    shelf: shelfReducer,
    cart: cartReducer,
    total: totalReducer,
    filters: filtersReducer,
    sort: sortReducer
});