import React, {Component} from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {SearchBox} from "../SearchBox/SearchBox";
import {withTranslation} from "react-i18next";

export class PageSearchBox extends Component {

    changeLanguage(i18n, lng) {
        i18n.changeLanguage(lng);
    };

    render() {
        const {i18n} = this.props;

        return (
            <React.Fragment>
                <Header hotel="spring"/>
                <SearchBox lang={i18n} interno={this.props.interno} withHeader={true}/>
                <Footer hotel="spring"/>
            </React.Fragment>
        );
    }
}

export default withTranslation('common')(PageSearchBox);