import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector, useDispatch} from "react-redux";
import Toggle from 'react-toggle'
import {commissionViewUpdate} from "../../actions/agency";


export default function AgencyBanner() {
    const {t} = useTranslation('common');
    const dispatch = useDispatch();
    const isCommissionView = useSelector(state => state.agency.isCommissionView);

    return (
        <section className="search-result container">
            <div className="search-result-header">
                <div id="hotel_banner" className="hotel-banner">
                    <Toggle
                        id='cheese-status'
                        defaultChecked={isCommissionView}
                        onChange={() =>
                            dispatch(commissionViewUpdate(!isCommissionView))
                        }/>
                    <div className="info-wrapper">
                        <div className="short-info">
                            <p>{t('msg.agency_commissionview_activate').toUpperCase()}</p><br/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}