// App.js
import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {Provider} from 'react-redux';
import store from "./store";

import Register from "./components/accounts/Register";
import LoginPage from "./components/Login/LoginPage";
import LoginPageCallCenter from "./components/LoginCallCenter/LoginPageCallCenter";

import {Provider as AlertProvider} from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import Alerts from "./components/layout/Alerts";
import {loadUser, autoLoadUser} from "./actions/auth";
import {Cookies, withCookies} from 'react-cookie';

import CheckOut from "./components/Checkout/checkout";
import Dashboard from "./components/Dashboard";

import "./static/css/style.css"

import {ThemeProvider} from './theme-provider';
import {AronaTheme} from "./themes/arona-theme";
import {BitacoraTheme} from "./themes/bitacora-theme";
import {VulcanoTheme} from "./themes/vulcano-theme";
import {SpringTheme} from "./themes/spring-theme";

import BookingEngine from "./components/BookingEngine/BookingEngine";
import Success from "./components/Success/Success";
import {PageSearchBox} from "./components/PageSearchBox/PageSearchBox";
import {withTranslation} from "react-i18next";

import THNScript from "./components/TheHotelsNetwork";
import PasswordRecovery from "./components/PasswordRecovery";
import ModifyPassword from "./components/PasswordRecovery/ModifyPassword";
import BookingDetail from "./components/Dashboard/Bookings/BookingDetail";
import Cancelation from "./components/Dashboard/Cancelation";
import BookingRoom from "./components/Dashboard/Bookings/BookingRoom";

// Alert Options
const alertOptions = {
    timeout: 3000,
    position: "middle",
};

class App extends Component {

    constructor(props) {
        super(props);
        this.arona = AronaTheme;
        this.bitacora = BitacoraTheme;
        this.vulcano = VulcanoTheme;
        this.spring = SpringTheme;
        this.state = {
            theme: this.spring,
            hotelName: "spring",
        };
    }

    handleSelectOnTheme = (themeName) => {
        this.setState({
            theme: this[themeName],
            hotelName: themeName,
        });
    };

    async componentDidMount() {
        const storeRedux = store.getState();

        if(storeRedux.auth.isAuthenticated){
            store.dispatch(loadUser());
        }
        else if(this.props.cookies.cookies['sph-auth']){
            store.dispatch(autoLoadUser(this.props.cookies));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot): void {
        if(this.state.hotelName !== "spring") {
            THNScript(this.state.hotelName);
        }
    }

    render() {
        const {t} = this.props;

        return (
            <Provider store={store}>
                <ThemeProvider variables={this.state.theme}>
                    <AlertProvider template={AlertTemplate} {...alertOptions}>
                        <Router>
                            <Alerts/>
                            <Switch>
                                <Route exact path="/search">
                                    <PageSearchBox/>
                                </Route>

                                <Route exact path="/">
                                    <PageSearchBox/>
                                </Route>

                                <Route exact path="/password-reset">
                                    <PasswordRecovery/>
                                </Route>
                                <Route path="/password-modify/:email/:token">
                                    <ModifyPassword/>
                                </Route>

                                <Route exact path="/binterna">
                                    <PageSearchBox interno={true}/>
                                </Route>

                                <Route exact path="/login" component={LoginPage}/>
                                <Route exact path="/contact-centre" component={LoginPageCallCenter}/>
                                <Route exact path="/register" component={Register}/>

                                {/*<Route exact path="/club">*/}
                                {/*    <Dashboard page="club"/>*/}
                                {/*</Route>*/}
                                <Route path="/user">
                                    <Dashboard page="userdata"/>
                                </Route>
                                <Route exact path="/myreservations">
                                    <Dashboard page="bookings"/>
                                </Route>
                                <Route exact path="/agency/agents">
                                    <Dashboard page="agents"/>
                                </Route>

                                <Route exact path="/myreservations/detail/:idreservation">
                                    <BookingDetail />
                                </Route>
                                <Route exact path="/myreservations/room/:pmskey">
                                    <BookingRoom />
                                </Route>
                                <Route exact path="/myreservations/cancelation/room/:pmskey">
                                    <Cancelation />
                                </Route>

                                <Route path="/:hotelname?" exact render={(props) =>
                                    {if(props.match.params.hotelname === "arona" ||
                                        props.match.params.hotelname === "bitacora" ||
                                        props.match.params.hotelname === "vulcano")
                                    {
                                        return <BookingEngine {...props} handleSelectOnThemesInApp={this.handleSelectOnTheme}/>
                                    }else{
                                        return <Redirect to="/" />
                                    }}
                                }
                                />

                                {/*<Route path="/:hotelname?" exact render={(props) => (*/}
                                {/*    <BookingEngine {...props} handleSelectOnThemesInApp={this.handleSelectOnTheme}/>*/}
                                {/*)}*/}
                                {/*/>*/}
                                <Route path="/checkout/:hotelname?" exact render={(props) => (
                                    <CheckOut {...props} handleSelectOnThemesInApp={this.handleSelectOnTheme}/>
                                )}
                                />
                                <Route path="/success/:hotelname?" exact render={(props) => (
                                    <Success {...props} handleSelectOnThemesInApp={this.handleSelectOnTheme}/>
                                )}
                                />

                            </Switch>
                        </Router>
                    </AlertProvider>
                </ThemeProvider>
            </Provider>
        );
    }

}

export default withCookies(withTranslation('common')(App));